export const getYPointAtX = (pathNode, xCo) => {
  const pathLength = pathNode.getTotalLength();
  let currentLocation = 0;
  const step = 1;
  while (true) {
    if (currentLocation >= pathLength) break;
    const {x,y} = pathNode.getPointAtLength(currentLocation);
    const howClose = Math.abs(x - xCo);
    if ( howClose < step){ return y}
    currentLocation += step;
  }
}