import * as d3 from "d3";
import { getYPointAtX } from "./svgPathSampler";

// Use the url to fetch the file asynchronously

const days = 4;
const setRange = 4 * days; // 4 pts of data a time, generally
const tones = [230, 170, 240, 160];

export function lineChart(
	data,
	{
		curve = d3.curveCatmullRom, // method of interpolation between points
		marginTop = 40, // top margin, in pixels
		marginBottom = 20, // bottom margin, in pixels
		width = 800, // outer width, in pixels
		height = 400, // outer height, in pixels
		strokeWidth = 0.5, // stroke width of line, in pixels,
		transition = 1200,
		easing = d3.easeLinear,
	} = {}
) {
	// Helpers
	const dx = (d) => d[0];
	const dy = (d) => d[1];

	// Y axis
	const yExtent = [d3.min(data, dy), d3.max(data, dy)];
	const y = d3
		.scaleLinear()
		.domain(yExtent)
		.range([height - marginBottom, marginTop])
	const yAxis = d3.axisLeft(y);

	const getX = (nd) => {
		const xExtent = d3.extent(nd, dx);
		return d3.scaleTime().domain(xExtent).range([0, width]);
	};

	// Construct a line generator.
	const line = (nd) =>
		d3
			.line()
			.x((d) => getX(nd)(d[0]))
			.y((d) => y(d[1]))
			.curve(curve);
    const area = (nd) =>
        d3
            .area()
            .x((d) => getX(nd)(d[0]))
            .y0(height - marginBottom)
            .y1((d) => y(d[1]))
            .curve(curve);

	const tickFormat = d3.timeFormat("%b %d");

	const now = Date.now();
	let nowRange = [];
	data.every((p, i) => {
		if ((i + 1) % 2) {
			// to line up the odd date...
			const thisDate = new Date(p[0]);
			const diff = now - thisDate;
			const dayDiff = diff / 1000 / 60 / 60 / 24;
			const withinDays = Math.abs(dayDiff) < days / 2;
			if (withinDays) {
				nowRange = data.slice(i, i + setRange);
				return false;
			}
		}
		return true;
	});

	const nowX = getX(nowRange);

	// SETUP
	const svg = d3
		.select("#tidelayer")
		.append("svg")
		.attr("width", width)
		.attr("height", height)
		.attr("viewBox", [0, -marginBottom, width, height + marginTop]);

	// Axes
	svg.append("g").attr("id", "xAxis");
	const nowAxis = d3.axisBottom(nowX).tickFormat(tickFormat).ticks(5);
	const nowColor = `rgba(30,120,255,0.8)`;
	svg
		.append("g")
		.attr("id", "nowAxis")
		.call(nowAxis)
		.attr("stroke", nowColor)
		.attr("transform", `translate(0,${height - marginBottom})`);
	svg.append("g").attr("id", "yAxis").call(yAxis);

	// G
	svg
		.append("path")
		.attr("class", "line")
		.attr("fill", "none")
		.attr("stroke", "currentColor")
		.attr("stroke-width", 2);

    const nowArea = area(nowRange)(nowRange);
	const nowLine = line(nowRange)(nowRange);
    svg
        .append("path")
        .attr("id", "now")
        .attr("fill", nowColor)
        .attr('opacity', 0.4)
        .attr("d", nowArea);
	const nowPath = svg
		.append("path")
		.attr("id", "now")
		.attr("fill", "none")
		.attr("stroke", nowColor)
		.attr("stroke-width", 6)
		.attr("d", nowLine);

	const colors = tones.map((n) => `rgb(${n},${n},${n})`);
	colors.forEach((color, i) => {
		svg
			.append("path")
			.attr("class", `class${i}`)
			.attr("fill", "none")
			.attr("stroke", color)
			.attr("stroke-width", strokeWidth);
	});
	svg
		.append("g")
		.attr("id", "circles")
		.selectAll("circle")
		.data(data)
		.enter()
		.append("circle")
		.attr("r", 4)
		.attr("fill", "black")
		.attr("stroke", "white");

	const targetX = getX(nowRange)(now);
	const targetY = getYPointAtX(nowPath.node(), targetX);
	svg
		.append("g")
		.attr("id", "now")
		.append("circle")
		.attr("r", 8)
		.attr("fill", "black")
		.attr("stroke", nowColor)
		.attr("stroke-width", 4)
		.attr("cx", targetX)
		.attr("cy", targetY);

    // Add a y axis label at 0 
    svg.append("text")
        .attr("class", "y label")
        .attr("text-anchor", "end")
        .attr("y", height - marginBottom)
        .attr("x", "-8")
        .attr('fill', 'currentColor')
        .text("cm");

	// LOOP
	let counter = 0;
	const l = data.length;
	const all = [...data, ...data];

	function update() {
		// Advance 2 full cycles (LHLH)
		counter = (counter + 4) % l;
		const newData = all.slice(counter, setRange + counter);
		const x = getX(newData);
		const xAxis = d3.axisTop(x).tickFormat(tickFormat).ticks(5);

		// Update axis

		svg
			.select(".line")
			.transition()
			.ease(easing)
			.duration(transition)
			.attr("d", line(newData)(newData));

		colors.forEach((_, i) => {
			const filteredSet = newData.filter((_, j) => j % 4 === i);
            /*
            svg.select(`.class${i}a`)
                .transition()
                .ease(easing)
                .duration(transition)
                .attr("d", area(newData)(filteredSet))
                .attr("fill", nowColor);
            */
            svg
				.select(`.class${i}`)
				.transition()
				.ease(easing)
				.duration(transition)
				.attr("d", line(newData)(filteredSet));
		});

		svg
			.select("#xAxis")
			.transition()
			.ease(easing)
			.duration(transition)
			.call(xAxis);

		// Circle moves
		const c = svg.select("#circles").selectAll("circle").data(newData);
		c.exit().remove(); //remove unneeded circles
		c.enter().append("circle"); //create any new circles needed
		c.transition()
			.ease(easing)
			.duration(transition)
			.attr("cx", (d) => x(d[0]))
			.attr("cy", (d) => y(d[1]));
	}

	update();
	setInterval(update, transition);
}

// GO!
export const instantiateTideD3 = async () => {
		const tideObject = tideData.map(([ date, cm ]) => [
			new Date(date),
			parseInt(cm),
		]);
		const { innerWidth } = window;
		lineChart(tideObject, { width: innerWidth - 128, height: 600 });
};


const tideData = [
    [
        "2023-01-01T08:18:00.000Z",
        62
    ],
    [
        "2023-01-01T15:04:00.000Z",
        188
    ],
    [
        "2023-01-01T22:08:00.000Z",
        9
    ],
    [
        "2023-01-02T04:52:00.000Z",
        124
    ],
    [
        "2023-01-02T09:15:00.000Z",
        78
    ],
    [
        "2023-01-02T15:45:00.000Z",
        191
    ],
    [
        "2023-01-02T22:56:00.000Z",
        -4
    ],
    [
        "2023-01-03T05:58:00.000Z",
        132
    ],
    [
        "2023-01-03T10:09:00.000Z",
        90
    ],
    [
        "2023-01-03T16:25:00.000Z",
        192
    ],
    [
        "2023-01-03T23:38:00.000Z",
        -12
    ],
    [
        "2023-01-04T06:52:00.000Z",
        140
    ],
    [
        "2023-01-04T11:00:00.000Z",
        97
    ],
    [
        "2023-01-04T17:03:00.000Z",
        192
    ],
    [
        "2023-01-05T00:16:00.000Z",
        -17
    ],
    [
        "2023-01-05T07:37:00.000Z",
        144
    ],
    [
        "2023-01-05T11:46:00.000Z",
        101
    ],
    [
        "2023-01-05T17:41:00.000Z",
        190
    ],
    [
        "2023-01-06T00:52:00.000Z",
        -19
    ],
    [
        "2023-01-06T08:17:00.000Z",
        146
    ],
    [
        "2023-01-06T12:28:00.000Z",
        102
    ],
    [
        "2023-01-06T18:18:00.000Z",
        189
    ],
    [
        "2023-01-07T01:26:00.000Z",
        -19
    ],
    [
        "2023-01-07T08:53:00.000Z",
        145
    ],
    [
        "2023-01-07T13:07:00.000Z",
        101
    ],
    [
        "2023-01-07T18:54:00.000Z",
        186
    ],
    [
        "2023-01-08T01:58:00.000Z",
        -18
    ],
    [
        "2023-01-08T09:25:00.000Z",
        144
    ],
    [
        "2023-01-08T13:45:00.000Z",
        98
    ],
    [
        "2023-01-08T19:31:00.000Z",
        181
    ],
    [
        "2023-01-09T02:30:00.000Z",
        -16
    ],
    [
        "2023-01-09T09:56:00.000Z",
        143
    ],
    [
        "2023-01-09T14:23:00.000Z",
        96
    ],
    [
        "2023-01-09T20:07:00.000Z",
        175
    ],
    [
        "2023-01-10T03:02:00.000Z",
        -11
    ],
    [
        "2023-01-10T10:25:00.000Z",
        144
    ],
    [
        "2023-01-10T15:04:00.000Z",
        93
    ],
    [
        "2023-01-10T20:45:00.000Z",
        166
    ],
    [
        "2023-01-11T03:34:00.000Z",
        -4
    ],
    [
        "2023-01-11T10:55:00.000Z",
        145
    ],
    [
        "2023-01-11T15:51:00.000Z",
        89
    ],
    [
        "2023-01-11T21:26:00.000Z",
        153
    ],
    [
        "2023-01-12T04:07:00.000Z",
        6
    ],
    [
        "2023-01-12T11:26:00.000Z",
        149
    ],
    [
        "2023-01-12T16:46:00.000Z",
        83
    ],
    [
        "2023-01-12T22:13:00.000Z",
        139
    ],
    [
        "2023-01-13T04:42:00.000Z",
        20
    ],
    [
        "2023-01-13T11:59:00.000Z",
        154
    ],
    [
        "2023-01-13T17:49:00.000Z",
        73
    ],
    [
        "2023-01-13T23:14:00.000Z",
        124
    ],
    [
        "2023-01-14T05:20:00.000Z",
        37
    ],
    [
        "2023-01-14T12:35:00.000Z",
        161
    ],
    [
        "2023-01-14T18:58:00.000Z",
        59
    ],
    [
        "2023-01-15T00:38:00.000Z",
        112
    ],
    [
        "2023-01-15T06:06:00.000Z",
        55
    ],
    [
        "2023-01-15T13:14:00.000Z",
        168
    ],
    [
        "2023-01-15T20:05:00.000Z",
        41
    ],
    [
        "2023-01-16T02:26:00.000Z",
        107
    ],
    [
        "2023-01-16T07:00:00.000Z",
        74
    ],
    [
        "2023-01-16T13:57:00.000Z",
        177
    ],
    [
        "2023-01-16T21:05:00.000Z",
        20
    ],
    [
        "2023-01-17T04:08:00.000Z",
        114
    ],
    [
        "2023-01-17T08:05:00.000Z",
        88
    ],
    [
        "2023-01-17T14:45:00.000Z",
        187
    ],
    [
        "2023-01-17T22:00:00.000Z",
        -2
    ],
    [
        "2023-01-18T05:24:00.000Z",
        126
    ],
    [
        "2023-01-18T09:11:00.000Z",
        98
    ],
    [
        "2023-01-18T15:36:00.000Z",
        197
    ],
    [
        "2023-01-18T22:51:00.000Z",
        -22
    ],
    [
        "2023-01-19T06:20:00.000Z",
        137
    ],
    [
        "2023-01-19T10:13:00.000Z",
        101
    ],
    [
        "2023-01-19T16:28:00.000Z",
        207
    ],
    [
        "2023-01-19T23:40:00.000Z",
        -38
    ],
    [
        "2023-01-20T07:06:00.000Z",
        145
    ],
    [
        "2023-01-20T11:10:00.000Z",
        98
    ],
    [
        "2023-01-20T17:22:00.000Z",
        215
    ],
    [
        "2023-01-21T00:28:00.000Z",
        -49
    ],
    [
        "2023-01-21T07:49:00.000Z",
        151
    ],
    [
        "2023-01-21T12:05:00.000Z",
        92
    ],
    [
        "2023-01-21T18:15:00.000Z",
        218
    ],
    [
        "2023-01-22T01:15:00.000Z",
        -54
    ],
    [
        "2023-01-22T08:30:00.000Z",
        156
    ],
    [
        "2023-01-22T12:59:00.000Z",
        84
    ],
    [
        "2023-01-22T19:08:00.000Z",
        216
    ],
    [
        "2023-01-23T02:00:00.000Z",
        -51
    ],
    [
        "2023-01-23T09:10:00.000Z",
        161
    ],
    [
        "2023-01-23T13:54:00.000Z",
        74
    ],
    [
        "2023-01-23T20:01:00.000Z",
        207
    ],
    [
        "2023-01-24T02:44:00.000Z",
        -42
    ],
    [
        "2023-01-24T09:49:00.000Z",
        165
    ],
    [
        "2023-01-24T14:51:00.000Z",
        66
    ],
    [
        "2023-01-24T20:55:00.000Z",
        191
    ],
    [
        "2023-01-25T03:27:00.000Z",
        -26
    ],
    [
        "2023-01-25T10:29:00.000Z",
        170
    ],
    [
        "2023-01-25T15:53:00.000Z",
        58
    ],
    [
        "2023-01-25T21:52:00.000Z",
        170
    ],
    [
        "2023-01-26T04:10:00.000Z",
        -5
    ],
    [
        "2023-01-26T11:10:00.000Z",
        174
    ],
    [
        "2023-01-26T17:00:00.000Z",
        50
    ],
    [
        "2023-01-26T22:56:00.000Z",
        147
    ],
    [
        "2023-01-27T04:54:00.000Z",
        19
    ],
    [
        "2023-01-27T11:52:00.000Z",
        177
    ],
    [
        "2023-01-27T18:13:00.000Z",
        41
    ],
    [
        "2023-01-28T00:14:00.000Z",
        126
    ],
    [
        "2023-01-28T05:42:00.000Z",
        45
    ],
    [
        "2023-01-28T12:37:00.000Z",
        179
    ],
    [
        "2023-01-28T19:28:00.000Z",
        31
    ],
    [
        "2023-01-29T01:51:00.000Z",
        115
    ],
    [
        "2023-01-29T06:39:00.000Z",
        68
    ],
    [
        "2023-01-29T13:25:00.000Z",
        179
    ],
    [
        "2023-01-29T20:40:00.000Z",
        20
    ],
    [
        "2023-01-30T03:36:00.000Z",
        117
    ],
    [
        "2023-01-30T07:46:00.000Z",
        86
    ],
    [
        "2023-01-30T14:16:00.000Z",
        179
    ],
    [
        "2023-01-30T21:42:00.000Z",
        9
    ],
    [
        "2023-01-31T04:58:00.000Z",
        126
    ],
    [
        "2023-01-31T08:58:00.000Z",
        97
    ],
    [
        "2023-01-31T15:08:00.000Z",
        180
    ],
    [
        "2023-01-31T22:35:00.000Z",
        0
    ],
    [
        "2023-02-01T05:56:00.000Z",
        136
    ],
    [
        "2023-02-01T10:02:00.000Z",
        101
    ],
    [
        "2023-02-01T15:58:00.000Z",
        180
    ],
    [
        "2023-02-01T23:20:00.000Z",
        -6
    ],
    [
        "2023-02-02T06:40:00.000Z",
        142
    ],
    [
        "2023-02-02T10:55:00.000Z",
        100
    ],
    [
        "2023-02-02T16:44:00.000Z",
        182
    ],
    [
        "2023-02-02T23:59:00.000Z",
        -11
    ],
    [
        "2023-02-03T07:17:00.000Z",
        145
    ],
    [
        "2023-02-03T11:39:00.000Z",
        96
    ],
    [
        "2023-02-03T17:27:00.000Z",
        183
    ],
    [
        "2023-02-04T00:34:00.000Z",
        -13
    ],
    [
        "2023-02-04T07:49:00.000Z",
        146
    ],
    [
        "2023-02-04T12:18:00.000Z",
        91
    ],
    [
        "2023-02-04T18:06:00.000Z",
        183
    ],
    [
        "2023-02-05T01:06:00.000Z",
        -14
    ],
    [
        "2023-02-05T08:17:00.000Z",
        145
    ],
    [
        "2023-02-05T12:54:00.000Z",
        86
    ],
    [
        "2023-02-05T18:44:00.000Z",
        181
    ],
    [
        "2023-02-06T01:35:00.000Z",
        -13
    ],
    [
        "2023-02-06T08:42:00.000Z",
        146
    ],
    [
        "2023-02-06T13:28:00.000Z",
        80
    ],
    [
        "2023-02-06T19:20:00.000Z",
        177
    ],
    [
        "2023-02-07T02:03:00.000Z",
        -10
    ],
    [
        "2023-02-07T09:06:00.000Z",
        148
    ],
    [
        "2023-02-07T14:03:00.000Z",
        74
    ],
    [
        "2023-02-07T19:56:00.000Z",
        170
    ],
    [
        "2023-02-08T02:30:00.000Z",
        -4
    ],
    [
        "2023-02-08T09:31:00.000Z",
        150
    ],
    [
        "2023-02-08T14:40:00.000Z",
        68
    ],
    [
        "2023-02-08T20:34:00.000Z",
        161
    ],
    [
        "2023-02-09T02:57:00.000Z",
        5
    ],
    [
        "2023-02-09T09:56:00.000Z",
        154
    ],
    [
        "2023-02-09T15:21:00.000Z",
        62
    ],
    [
        "2023-02-09T21:15:00.000Z",
        149
    ],
    [
        "2023-02-10T03:25:00.000Z",
        19
    ],
    [
        "2023-02-10T10:23:00.000Z",
        158
    ],
    [
        "2023-02-10T16:07:00.000Z",
        55
    ],
    [
        "2023-02-10T22:03:00.000Z",
        135
    ],
    [
        "2023-02-11T03:56:00.000Z",
        35
    ],
    [
        "2023-02-11T10:52:00.000Z",
        163
    ],
    [
        "2023-02-11T17:01:00.000Z",
        47
    ],
    [
        "2023-02-11T23:06:00.000Z",
        121
    ],
    [
        "2023-02-12T04:31:00.000Z",
        55
    ],
    [
        "2023-02-12T11:27:00.000Z",
        167
    ],
    [
        "2023-02-12T18:05:00.000Z",
        37
    ],
    [
        "2023-02-13T00:34:00.000Z",
        110
    ],
    [
        "2023-02-13T05:14:00.000Z",
        74
    ],
    [
        "2023-02-13T12:11:00.000Z",
        171
    ],
    [
        "2023-02-13T19:17:00.000Z",
        24
    ],
    [
        "2023-02-14T02:32:00.000Z",
        108
    ],
    [
        "2023-02-14T06:15:00.000Z",
        91
    ],
    [
        "2023-02-14T13:05:00.000Z",
        176
    ],
    [
        "2023-02-14T20:29:00.000Z",
        9
    ],
    [
        "2023-02-15T04:15:00.000Z",
        118
    ],
    [
        "2023-02-15T07:39:00.000Z",
        102
    ],
    [
        "2023-02-15T14:08:00.000Z",
        183
    ],
    [
        "2023-02-15T21:35:00.000Z",
        -8
    ],
    [
        "2023-02-16T05:18:00.000Z",
        130
    ],
    [
        "2023-02-16T09:02:00.000Z",
        104
    ],
    [
        "2023-02-16T15:14:00.000Z",
        191
    ],
    [
        "2023-02-16T22:32:00.000Z",
        -24
    ],
    [
        "2023-02-17T06:03:00.000Z",
        139
    ],
    [
        "2023-02-17T10:09:00.000Z",
        97
    ],
    [
        "2023-02-17T16:17:00.000Z",
        199
    ],
    [
        "2023-02-17T23:23:00.000Z",
        -36
    ],
    [
        "2023-02-18T06:42:00.000Z",
        148
    ],
    [
        "2023-02-18T11:07:00.000Z",
        85
    ],
    [
        "2023-02-18T17:15:00.000Z",
        206
    ],
    [
        "2023-02-19T00:10:00.000Z",
        -42
    ],
    [
        "2023-02-19T07:18:00.000Z",
        155
    ],
    [
        "2023-02-19T12:01:00.000Z",
        71
    ],
    [
        "2023-02-19T18:10:00.000Z",
        208
    ],
    [
        "2023-02-20T00:54:00.000Z",
        -42
    ],
    [
        "2023-02-20T07:54:00.000Z",
        161
    ],
    [
        "2023-02-20T12:52:00.000Z",
        56
    ],
    [
        "2023-02-20T19:04:00.000Z",
        203
    ],
    [
        "2023-02-21T01:35:00.000Z",
        -35
    ],
    [
        "2023-02-21T08:29:00.000Z",
        168
    ],
    [
        "2023-02-21T13:44:00.000Z",
        43
    ],
    [
        "2023-02-21T19:57:00.000Z",
        192
    ],
    [
        "2023-02-22T02:15:00.000Z",
        -21
    ],
    [
        "2023-02-22T09:04:00.000Z",
        174
    ],
    [
        "2023-02-22T14:37:00.000Z",
        33
    ],
    [
        "2023-02-22T20:51:00.000Z",
        176
    ],
    [
        "2023-02-23T02:54:00.000Z",
        -2
    ],
    [
        "2023-02-23T09:39:00.000Z",
        178
    ],
    [
        "2023-02-23T15:31:00.000Z",
        25
    ],
    [
        "2023-02-23T21:48:00.000Z",
        156
    ],
    [
        "2023-02-24T03:33:00.000Z",
        21
    ],
    [
        "2023-02-24T10:15:00.000Z",
        180
    ],
    [
        "2023-02-24T16:29:00.000Z",
        21
    ],
    [
        "2023-02-24T22:52:00.000Z",
        137
    ],
    [
        "2023-02-25T04:15:00.000Z",
        45
    ],
    [
        "2023-02-25T10:54:00.000Z",
        178
    ],
    [
        "2023-02-25T17:32:00.000Z",
        20
    ],
    [
        "2023-02-26T00:10:00.000Z",
        122
    ],
    [
        "2023-02-26T05:03:00.000Z",
        69
    ],
    [
        "2023-02-26T11:36:00.000Z",
        174
    ],
    [
        "2023-02-26T18:42:00.000Z",
        18
    ],
    [
        "2023-02-27T01:51:00.000Z",
        116
    ],
    [
        "2023-02-27T06:05:00.000Z",
        88
    ],
    [
        "2023-02-27T12:27:00.000Z",
        168
    ],
    [
        "2023-02-27T19:56:00.000Z",
        16
    ],
    [
        "2023-02-28T03:34:00.000Z",
        121
    ],
    [
        "2023-02-28T07:29:00.000Z",
        100
    ],
    [
        "2023-02-28T13:27:00.000Z",
        163
    ],
    [
        "2023-02-28T21:06:00.000Z",
        11
    ],
    [
        "2023-03-01T04:45:00.000Z",
        130
    ],
    [
        "2023-03-01T08:51:00.000Z",
        102
    ],
    [
        "2023-03-01T14:32:00.000Z",
        162
    ],
    [
        "2023-03-01T22:04:00.000Z",
        6
    ],
    [
        "2023-03-02T05:32:00.000Z",
        137
    ],
    [
        "2023-03-02T09:55:00.000Z",
        97
    ],
    [
        "2023-03-02T15:33:00.000Z",
        164
    ],
    [
        "2023-03-02T22:52:00.000Z",
        1
    ],
    [
        "2023-03-03T06:09:00.000Z",
        141
    ],
    [
        "2023-03-03T10:45:00.000Z",
        90
    ],
    [
        "2023-03-03T16:25:00.000Z",
        167
    ],
    [
        "2023-03-03T23:31:00.000Z",
        -3
    ],
    [
        "2023-03-04T06:39:00.000Z",
        144
    ],
    [
        "2023-03-04T11:26:00.000Z",
        80
    ],
    [
        "2023-03-04T17:11:00.000Z",
        169
    ],
    [
        "2023-03-05T00:05:00.000Z",
        -5
    ],
    [
        "2023-03-05T07:05:00.000Z",
        145
    ],
    [
        "2023-03-05T12:02:00.000Z",
        71
    ],
    [
        "2023-03-05T17:52:00.000Z",
        170
    ],
    [
        "2023-03-06T00:34:00.000Z",
        -5
    ],
    [
        "2023-03-06T07:29:00.000Z",
        147
    ],
    [
        "2023-03-06T12:36:00.000Z",
        62
    ],
    [
        "2023-03-06T18:31:00.000Z",
        169
    ],
    [
        "2023-03-07T01:01:00.000Z",
        -2
    ],
    [
        "2023-03-07T07:51:00.000Z",
        150
    ],
    [
        "2023-03-07T13:08:00.000Z",
        54
    ],
    [
        "2023-03-07T19:10:00.000Z",
        165
    ],
    [
        "2023-03-08T01:27:00.000Z",
        4
    ],
    [
        "2023-03-08T08:13:00.000Z",
        154
    ],
    [
        "2023-03-08T13:42:00.000Z",
        45
    ],
    [
        "2023-03-08T19:49:00.000Z",
        158
    ],
    [
        "2023-03-09T01:52:00.000Z",
        13
    ],
    [
        "2023-03-09T08:35:00.000Z",
        159
    ],
    [
        "2023-03-09T14:17:00.000Z",
        35
    ],
    [
        "2023-03-09T20:30:00.000Z",
        150
    ],
    [
        "2023-03-10T02:19:00.000Z",
        25
    ],
    [
        "2023-03-10T08:59:00.000Z",
        164
    ],
    [
        "2023-03-10T14:55:00.000Z",
        27
    ],
    [
        "2023-03-10T21:16:00.000Z",
        140
    ],
    [
        "2023-03-11T02:48:00.000Z",
        41
    ],
    [
        "2023-03-11T09:26:00.000Z",
        168
    ],
    [
        "2023-03-11T15:39:00.000Z",
        20
    ],
    [
        "2023-03-11T22:10:00.000Z",
        130
    ],
    [
        "2023-03-12T03:20:00.000Z",
        58
    ],
    [
        "2023-03-12T09:56:00.000Z",
        170
    ],
    [
        "2023-03-12T16:29:00.000Z",
        14
    ],
    [
        "2023-03-12T23:19:00.000Z",
        119
    ],
    [
        "2023-03-13T03:57:00.000Z",
        75
    ],
    [
        "2023-03-13T10:34:00.000Z",
        171
    ],
    [
        "2023-03-13T17:30:00.000Z",
        10
    ],
    [
        "2023-03-14T00:52:00.000Z",
        112
    ],
    [
        "2023-03-14T04:45:00.000Z",
        91
    ],
    [
        "2023-03-14T11:24:00.000Z",
        170
    ],
    [
        "2023-03-14T18:42:00.000Z",
        5
    ],
    [
        "2023-03-15T02:42:00.000Z",
        116
    ],
    [
        "2023-03-15T06:02:00.000Z",
        103
    ],
    [
        "2023-03-15T12:29:00.000Z",
        170
    ],
    [
        "2023-03-15T20:00:00.000Z",
        -3
    ],
    [
        "2023-03-16T04:01:00.000Z",
        125
    ],
    [
        "2023-03-16T07:44:00.000Z",
        105
    ],
    [
        "2023-03-16T13:47:00.000Z",
        172
    ],
    [
        "2023-03-16T21:10:00.000Z",
        -13
    ],
    [
        "2023-03-17T04:51:00.000Z",
        134
    ],
    [
        "2023-03-17T09:07:00.000Z",
        96
    ],
    [
        "2023-03-17T15:02:00.000Z",
        177
    ],
    [
        "2023-03-17T22:09:00.000Z",
        -22
    ],
    [
        "2023-03-18T05:30:00.000Z",
        144
    ],
    [
        "2023-03-18T10:11:00.000Z",
        80
    ],
    [
        "2023-03-18T16:10:00.000Z",
        183
    ],
    [
        "2023-03-18T22:59:00.000Z",
        -27
    ],
    [
        "2023-03-19T06:05:00.000Z",
        152
    ],
    [
        "2023-03-19T11:06:00.000Z",
        61
    ],
    [
        "2023-03-19T17:11:00.000Z",
        186
    ],
    [
        "2023-03-19T23:44:00.000Z",
        -27
    ],
    [
        "2023-03-20T06:39:00.000Z",
        161
    ],
    [
        "2023-03-20T11:56:00.000Z",
        41
    ],
    [
        "2023-03-20T18:07:00.000Z",
        185
    ],
    [
        "2023-03-21T00:25:00.000Z",
        -20
    ],
    [
        "2023-03-21T07:11:00.000Z",
        169
    ],
    [
        "2023-03-21T12:44:00.000Z",
        24
    ],
    [
        "2023-03-21T19:02:00.000Z",
        179
    ],
    [
        "2023-03-22T01:04:00.000Z",
        -8
    ],
    [
        "2023-03-22T07:44:00.000Z",
        177
    ],
    [
        "2023-03-22T13:32:00.000Z",
        9
    ],
    [
        "2023-03-22T19:56:00.000Z",
        169
    ],
    [
        "2023-03-23T01:42:00.000Z",
        9
    ],
    [
        "2023-03-23T08:16:00.000Z",
        182
    ],
    [
        "2023-03-23T14:20:00.000Z",
        0
    ],
    [
        "2023-03-23T20:51:00.000Z",
        157
    ],
    [
        "2023-03-24T02:20:00.000Z",
        29
    ],
    [
        "2023-03-24T08:48:00.000Z",
        183
    ],
    [
        "2023-03-24T15:08:00.000Z",
        -5
    ],
    [
        "2023-03-24T21:49:00.000Z",
        144
    ],
    [
        "2023-03-25T02:59:00.000Z",
        50
    ],
    [
        "2023-03-25T09:22:00.000Z",
        180
    ],
    [
        "2023-03-25T15:58:00.000Z",
        -4
    ],
    [
        "2023-03-25T22:53:00.000Z",
        132
    ],
    [
        "2023-03-26T03:41:00.000Z",
        70
    ],
    [
        "2023-03-26T09:58:00.000Z",
        174
    ],
    [
        "2023-03-26T16:52:00.000Z",
        0
    ],
    [
        "2023-03-27T00:09:00.000Z",
        124
    ],
    [
        "2023-03-27T04:33:00.000Z",
        88
    ],
    [
        "2023-03-27T10:39:00.000Z",
        165
    ],
    [
        "2023-03-27T17:53:00.000Z",
        6
    ],
    [
        "2023-03-28T01:42:00.000Z",
        122
    ],
    [
        "2023-03-28T05:45:00.000Z",
        100
    ],
    [
        "2023-03-28T11:31:00.000Z",
        156
    ],
    [
        "2023-03-28T19:04:00.000Z",
        10
    ],
    [
        "2023-03-29T03:10:00.000Z",
        126
    ],
    [
        "2023-03-29T07:19:00.000Z",
        104
    ],
    [
        "2023-03-29T12:36:00.000Z",
        148
    ],
    [
        "2023-03-29T20:16:00.000Z",
        11
    ],
    [
        "2023-03-30T04:10:00.000Z",
        131
    ],
    [
        "2023-03-30T08:39:00.000Z",
        98
    ],
    [
        "2023-03-30T13:51:00.000Z",
        144
    ],
    [
        "2023-03-30T21:18:00.000Z",
        9
    ],
    [
        "2023-03-31T04:51:00.000Z",
        136
    ],
    [
        "2023-03-31T09:38:00.000Z",
        88
    ],
    [
        "2023-03-31T15:00:00.000Z",
        145
    ],
    [
        "2023-03-31T22:07:00.000Z",
        7
    ],
    [
        "2023-04-01T05:23:00.000Z",
        140
    ],
    [
        "2023-04-01T10:25:00.000Z",
        75
    ],
    [
        "2023-04-01T15:59:00.000Z",
        148
    ],
    [
        "2023-04-01T22:47:00.000Z",
        5
    ],
    [
        "2023-04-02T05:49:00.000Z",
        143
    ],
    [
        "2023-04-02T11:05:00.000Z",
        62
    ],
    [
        "2023-04-02T16:49:00.000Z",
        150
    ],
    [
        "2023-04-02T23:20:00.000Z",
        6
    ],
    [
        "2023-04-03T06:11:00.000Z",
        147
    ],
    [
        "2023-04-03T11:40:00.000Z",
        49
    ],
    [
        "2023-04-03T17:35:00.000Z",
        151
    ],
    [
        "2023-04-03T23:50:00.000Z",
        10
    ],
    [
        "2023-04-04T06:33:00.000Z",
        152
    ],
    [
        "2023-04-04T12:14:00.000Z",
        37
    ],
    [
        "2023-04-04T18:19:00.000Z",
        150
    ],
    [
        "2023-04-05T00:18:00.000Z",
        16
    ],
    [
        "2023-04-05T06:55:00.000Z",
        158
    ],
    [
        "2023-04-05T12:46:00.000Z",
        24
    ],
    [
        "2023-04-05T19:03:00.000Z",
        148
    ],
    [
        "2023-04-06T00:45:00.000Z",
        26
    ],
    [
        "2023-04-06T07:18:00.000Z",
        164
    ],
    [
        "2023-04-06T13:20:00.000Z",
        12
    ],
    [
        "2023-04-06T19:48:00.000Z",
        144
    ],
    [
        "2023-04-07T01:13:00.000Z",
        38
    ],
    [
        "2023-04-07T07:42:00.000Z",
        170
    ],
    [
        "2023-04-07T13:55:00.000Z",
        1
    ],
    [
        "2023-04-07T20:36:00.000Z",
        140
    ],
    [
        "2023-04-08T01:44:00.000Z",
        52
    ],
    [
        "2023-04-08T08:08:00.000Z",
        174
    ],
    [
        "2023-04-08T14:34:00.000Z",
        -8
    ],
    [
        "2023-04-08T21:28:00.000Z",
        134
    ],
    [
        "2023-04-09T02:17:00.000Z",
        66
    ],
    [
        "2023-04-09T08:38:00.000Z",
        177
    ],
    [
        "2023-04-09T15:19:00.000Z",
        -13
    ],
    [
        "2023-04-09T22:28:00.000Z",
        128
    ],
    [
        "2023-04-10T02:54:00.000Z",
        80
    ],
    [
        "2023-04-10T09:14:00.000Z",
        176
    ],
    [
        "2023-04-10T16:10:00.000Z",
        -15
    ],
    [
        "2023-04-10T23:40:00.000Z",
        123
    ],
    [
        "2023-04-11T03:40:00.000Z",
        93
    ],
    [
        "2023-04-11T09:59:00.000Z",
        173
    ],
    [
        "2023-04-11T17:10:00.000Z",
        -14
    ],
    [
        "2023-04-12T01:04:00.000Z",
        122
    ],
    [
        "2023-04-12T04:44:00.000Z",
        102
    ],
    [
        "2023-04-12T10:57:00.000Z",
        167
    ],
    [
        "2023-04-12T18:20:00.000Z",
        -13
    ],
    [
        "2023-04-13T02:25:00.000Z",
        126
    ],
    [
        "2023-04-13T06:19:00.000Z",
        104
    ],
    [
        "2023-04-13T12:11:00.000Z",
        162
    ],
    [
        "2023-04-13T19:34:00.000Z",
        -13
    ],
    [
        "2023-04-14T03:26:00.000Z",
        134
    ],
    [
        "2023-04-14T07:57:00.000Z",
        95
    ],
    [
        "2023-04-14T13:35:00.000Z",
        158
    ],
    [
        "2023-04-14T20:41:00.000Z",
        -15
    ],
    [
        "2023-04-15T04:10:00.000Z",
        143
    ],
    [
        "2023-04-15T09:11:00.000Z",
        77
    ],
    [
        "2023-04-15T14:54:00.000Z",
        158
    ],
    [
        "2023-04-15T21:37:00.000Z",
        -14
    ],
    [
        "2023-04-16T04:48:00.000Z",
        153
    ],
    [
        "2023-04-16T10:10:00.000Z",
        55
    ],
    [
        "2023-04-16T16:05:00.000Z",
        159
    ],
    [
        "2023-04-16T22:26:00.000Z",
        -10
    ],
    [
        "2023-04-17T05:22:00.000Z",
        163
    ],
    [
        "2023-04-17T11:02:00.000Z",
        33
    ],
    [
        "2023-04-17T17:09:00.000Z",
        159
    ],
    [
        "2023-04-17T23:09:00.000Z",
        -2
    ],
    [
        "2023-04-18T05:55:00.000Z",
        173
    ],
    [
        "2023-04-18T11:50:00.000Z",
        12
    ],
    [
        "2023-04-18T18:08:00.000Z",
        157
    ],
    [
        "2023-04-18T23:49:00.000Z",
        11
    ],
    [
        "2023-04-19T06:27:00.000Z",
        181
    ],
    [
        "2023-04-19T12:35:00.000Z",
        -5
    ],
    [
        "2023-04-19T19:05:00.000Z",
        153
    ],
    [
        "2023-04-20T00:29:00.000Z",
        27
    ],
    [
        "2023-04-20T06:58:00.000Z",
        186
    ],
    [
        "2023-04-20T13:19:00.000Z",
        -17
    ],
    [
        "2023-04-20T20:01:00.000Z",
        148
    ],
    [
        "2023-04-21T01:08:00.000Z",
        45
    ],
    [
        "2023-04-21T07:29:00.000Z",
        187
    ],
    [
        "2023-04-21T14:02:00.000Z",
        -24
    ],
    [
        "2023-04-21T20:57:00.000Z",
        143
    ],
    [
        "2023-04-22T01:47:00.000Z",
        62
    ],
    [
        "2023-04-22T08:01:00.000Z",
        185
    ],
    [
        "2023-04-22T14:45:00.000Z",
        -25
    ],
    [
        "2023-04-22T21:54:00.000Z",
        138
    ],
    [
        "2023-04-23T02:29:00.000Z",
        77
    ],
    [
        "2023-04-23T08:35:00.000Z",
        179
    ],
    [
        "2023-04-23T15:29:00.000Z",
        -21
    ],
    [
        "2023-04-23T22:55:00.000Z",
        133
    ],
    [
        "2023-04-24T03:16:00.000Z",
        90
    ],
    [
        "2023-04-24T09:11:00.000Z",
        170
    ],
    [
        "2023-04-24T16:17:00.000Z",
        -14
    ],
    [
        "2023-04-25T00:03:00.000Z",
        129
    ],
    [
        "2023-04-25T04:12:00.000Z",
        100
    ],
    [
        "2023-04-25T09:53:00.000Z",
        160
    ],
    [
        "2023-04-25T17:11:00.000Z",
        -5
    ],
    [
        "2023-04-26T01:16:00.000Z",
        127
    ],
    [
        "2023-04-26T05:27:00.000Z",
        104
    ],
    [
        "2023-04-26T10:44:00.000Z",
        149
    ],
    [
        "2023-04-26T18:12:00.000Z",
        3
    ],
    [
        "2023-04-27T02:24:00.000Z",
        129
    ],
    [
        "2023-04-27T06:55:00.000Z",
        102
    ],
    [
        "2023-04-27T11:48:00.000Z",
        139
    ],
    [
        "2023-04-27T19:17:00.000Z",
        8
    ],
    [
        "2023-04-28T03:16:00.000Z",
        132
    ],
    [
        "2023-04-28T08:11:00.000Z",
        93
    ],
    [
        "2023-04-28T13:02:00.000Z",
        132
    ],
    [
        "2023-04-28T20:17:00.000Z",
        11
    ],
    [
        "2023-04-29T03:53:00.000Z",
        136
    ],
    [
        "2023-04-29T09:09:00.000Z",
        79
    ],
    [
        "2023-04-29T14:17:00.000Z",
        129
    ],
    [
        "2023-04-29T21:06:00.000Z",
        13
    ],
    [
        "2023-04-30T04:22:00.000Z",
        141
    ],
    [
        "2023-04-30T09:57:00.000Z",
        64
    ],
    [
        "2023-04-30T15:24:00.000Z",
        128
    ],
    [
        "2023-04-30T21:48:00.000Z",
        17
    ],
    [
        "2023-05-01T04:47:00.000Z",
        148
    ],
    [
        "2023-05-01T10:37:00.000Z",
        48
    ],
    [
        "2023-05-01T16:23:00.000Z",
        130
    ],
    [
        "2023-05-01T22:24:00.000Z",
        23
    ],
    [
        "2023-05-02T05:10:00.000Z",
        155
    ],
    [
        "2023-05-02T11:14:00.000Z",
        31
    ],
    [
        "2023-05-02T17:18:00.000Z",
        131
    ],
    [
        "2023-05-02T22:57:00.000Z",
        31
    ],
    [
        "2023-05-03T05:34:00.000Z",
        163
    ],
    [
        "2023-05-03T11:48:00.000Z",
        14
    ],
    [
        "2023-05-03T18:10:00.000Z",
        133
    ],
    [
        "2023-05-03T23:29:00.000Z",
        42
    ],
    [
        "2023-05-04T05:59:00.000Z",
        171
    ],
    [
        "2023-05-04T12:23:00.000Z",
        -2
    ],
    [
        "2023-05-04T19:01:00.000Z",
        135
    ],
    [
        "2023-05-05T00:02:00.000Z",
        53
    ],
    [
        "2023-05-05T06:25:00.000Z",
        178
    ],
    [
        "2023-05-05T12:58:00.000Z",
        -16
    ],
    [
        "2023-05-05T19:52:00.000Z",
        136
    ],
    [
        "2023-05-06T00:36:00.000Z",
        66
    ],
    [
        "2023-05-06T06:55:00.000Z",
        183
    ],
    [
        "2023-05-06T13:37:00.000Z",
        -27
    ],
    [
        "2023-05-06T20:45:00.000Z",
        136
    ],
    [
        "2023-05-07T01:13:00.000Z",
        77
    ],
    [
        "2023-05-07T07:28:00.000Z",
        187
    ],
    [
        "2023-05-07T14:19:00.000Z",
        -35
    ],
    [
        "2023-05-07T21:41:00.000Z",
        135
    ],
    [
        "2023-05-08T01:54:00.000Z",
        87
    ],
    [
        "2023-05-08T08:06:00.000Z",
        187
    ],
    [
        "2023-05-08T15:06:00.000Z",
        -38
    ],
    [
        "2023-05-08T22:40:00.000Z",
        134
    ],
    [
        "2023-05-09T02:42:00.000Z",
        96
    ],
    [
        "2023-05-09T08:51:00.000Z",
        184
    ],
    [
        "2023-05-09T15:58:00.000Z",
        -36
    ],
    [
        "2023-05-09T23:44:00.000Z",
        133
    ],
    [
        "2023-05-10T03:40:00.000Z",
        101
    ],
    [
        "2023-05-10T09:43:00.000Z",
        176
    ],
    [
        "2023-05-10T16:56:00.000Z",
        -31
    ],
    [
        "2023-05-11T00:49:00.000Z",
        134
    ],
    [
        "2023-05-11T04:57:00.000Z",
        102
    ],
    [
        "2023-05-11T10:47:00.000Z",
        166
    ],
    [
        "2023-05-11T18:00:00.000Z",
        -24
    ],
    [
        "2023-05-12T01:49:00.000Z",
        139
    ],
    [
        "2023-05-12T06:31:00.000Z",
        95
    ],
    [
        "2023-05-12T12:02:00.000Z",
        154
    ],
    [
        "2023-05-12T19:05:00.000Z",
        -17
    ],
    [
        "2023-05-13T02:40:00.000Z",
        147
    ],
    [
        "2023-05-13T07:57:00.000Z",
        79
    ],
    [
        "2023-05-13T13:25:00.000Z",
        144
    ],
    [
        "2023-05-13T20:05:00.000Z",
        -8
    ],
    [
        "2023-05-14T03:24:00.000Z",
        157
    ],
    [
        "2023-05-14T09:07:00.000Z",
        57
    ],
    [
        "2023-05-14T14:47:00.000Z",
        138
    ],
    [
        "2023-05-14T20:59:00.000Z",
        3
    ],
    [
        "2023-05-15T04:02:00.000Z",
        168
    ],
    [
        "2023-05-15T10:04:00.000Z",
        32
    ],
    [
        "2023-05-15T16:03:00.000Z",
        136
    ],
    [
        "2023-05-15T21:47:00.000Z",
        16
    ],
    [
        "2023-05-16T04:37:00.000Z",
        178
    ],
    [
        "2023-05-16T10:55:00.000Z",
        10
    ],
    [
        "2023-05-16T17:13:00.000Z",
        136
    ],
    [
        "2023-05-16T22:31:00.000Z",
        31
    ],
    [
        "2023-05-17T05:11:00.000Z",
        186
    ],
    [
        "2023-05-17T11:41:00.000Z",
        -9
    ],
    [
        "2023-05-17T18:16:00.000Z",
        137
    ],
    [
        "2023-05-17T23:14:00.000Z",
        47
    ],
    [
        "2023-05-18T05:44:00.000Z",
        191
    ],
    [
        "2023-05-18T12:24:00.000Z",
        -23
    ],
    [
        "2023-05-18T19:15:00.000Z",
        138
    ],
    [
        "2023-05-18T23:56:00.000Z",
        62
    ],
    [
        "2023-05-19T06:17:00.000Z",
        192
    ],
    [
        "2023-05-19T13:05:00.000Z",
        -30
    ],
    [
        "2023-05-19T20:11:00.000Z",
        139
    ],
    [
        "2023-05-20T00:39:00.000Z",
        76
    ],
    [
        "2023-05-20T06:50:00.000Z",
        190
    ],
    [
        "2023-05-20T13:45:00.000Z",
        -33
    ],
    [
        "2023-05-20T21:04:00.000Z",
        140
    ],
    [
        "2023-05-21T01:22:00.000Z",
        87
    ],
    [
        "2023-05-21T07:23:00.000Z",
        186
    ],
    [
        "2023-05-21T14:25:00.000Z",
        -31
    ],
    [
        "2023-05-21T21:56:00.000Z",
        138
    ],
    [
        "2023-05-22T02:08:00.000Z",
        96
    ],
    [
        "2023-05-22T07:59:00.000Z",
        179
    ],
    [
        "2023-05-22T15:06:00.000Z",
        -27
    ],
    [
        "2023-05-22T22:48:00.000Z",
        137
    ],
    [
        "2023-05-23T02:56:00.000Z",
        101
    ],
    [
        "2023-05-23T08:37:00.000Z",
        170
    ],
    [
        "2023-05-23T15:49:00.000Z",
        -20
    ],
    [
        "2023-05-23T23:41:00.000Z",
        134
    ],
    [
        "2023-05-24T03:51:00.000Z",
        104
    ],
    [
        "2023-05-24T09:20:00.000Z",
        160
    ],
    [
        "2023-05-24T16:35:00.000Z",
        -12
    ],
    [
        "2023-05-25T00:33:00.000Z",
        133
    ],
    [
        "2023-05-25T04:58:00.000Z",
        104
    ],
    [
        "2023-05-25T10:08:00.000Z",
        149
    ],
    [
        "2023-05-25T17:25:00.000Z",
        -3
    ],
    [
        "2023-05-26T01:23:00.000Z",
        134
    ],
    [
        "2023-05-26T06:14:00.000Z",
        99
    ],
    [
        "2023-05-26T11:04:00.000Z",
        137
    ],
    [
        "2023-05-26T18:16:00.000Z",
        5
    ],
    [
        "2023-05-27T02:05:00.000Z",
        137
    ],
    [
        "2023-05-27T07:27:00.000Z",
        89
    ],
    [
        "2023-05-27T12:11:00.000Z",
        126
    ],
    [
        "2023-05-27T19:07:00.000Z",
        14
    ],
    [
        "2023-05-28T02:41:00.000Z",
        142
    ],
    [
        "2023-05-28T08:29:00.000Z",
        75
    ],
    [
        "2023-05-28T13:26:00.000Z",
        118
    ],
    [
        "2023-05-28T19:55:00.000Z",
        23
    ],
    [
        "2023-05-29T03:11:00.000Z",
        149
    ],
    [
        "2023-05-29T09:20:00.000Z",
        57
    ],
    [
        "2023-05-29T14:44:00.000Z",
        114
    ],
    [
        "2023-05-29T20:40:00.000Z",
        33
    ],
    [
        "2023-05-30T03:39:00.000Z",
        158
    ],
    [
        "2023-05-30T10:04:00.000Z",
        38
    ],
    [
        "2023-05-30T15:58:00.000Z",
        114
    ],
    [
        "2023-05-30T21:21:00.000Z",
        45
    ],
    [
        "2023-05-31T04:07:00.000Z",
        167
    ],
    [
        "2023-05-31T10:43:00.000Z",
        19
    ],
    [
        "2023-05-31T17:05:00.000Z",
        118
    ],
    [
        "2023-05-31T22:01:00.000Z",
        57
    ],
    [
        "2023-06-01T04:36:00.000Z",
        177
    ],
    [
        "2023-06-01T11:21:00.000Z",
        -1
    ],
    [
        "2023-06-01T18:07:00.000Z",
        124
    ],
    [
        "2023-06-01T22:42:00.000Z",
        69
    ],
    [
        "2023-06-02T05:08:00.000Z",
        185
    ],
    [
        "2023-06-02T11:59:00.000Z",
        -18
    ],
    [
        "2023-06-02T19:03:00.000Z",
        131
    ],
    [
        "2023-06-02T23:23:00.000Z",
        80
    ],
    [
        "2023-06-03T05:42:00.000Z",
        193
    ],
    [
        "2023-06-03T12:39:00.000Z",
        -33
    ],
    [
        "2023-06-03T19:57:00.000Z",
        136
    ],
    [
        "2023-06-04T00:06:00.000Z",
        89
    ],
    [
        "2023-06-04T06:20:00.000Z",
        198
    ],
    [
        "2023-06-04T13:21:00.000Z",
        -44
    ],
    [
        "2023-06-04T20:49:00.000Z",
        140
    ],
    [
        "2023-06-05T00:52:00.000Z",
        95
    ],
    [
        "2023-06-05T07:03:00.000Z",
        201
    ],
    [
        "2023-06-05T14:07:00.000Z",
        -49
    ],
    [
        "2023-06-05T21:41:00.000Z",
        142
    ],
    [
        "2023-06-06T01:42:00.000Z",
        98
    ],
    [
        "2023-06-06T07:50:00.000Z",
        199
    ],
    [
        "2023-06-06T14:56:00.000Z",
        -50
    ],
    [
        "2023-06-06T22:32:00.000Z",
        143
    ],
    [
        "2023-06-07T02:38:00.000Z",
        99
    ],
    [
        "2023-06-07T08:41:00.000Z",
        193
    ],
    [
        "2023-06-07T15:47:00.000Z",
        -46
    ],
    [
        "2023-06-07T23:24:00.000Z",
        145
    ],
    [
        "2023-06-08T03:43:00.000Z",
        98
    ],
    [
        "2023-06-08T09:38:00.000Z",
        182
    ],
    [
        "2023-06-08T16:41:00.000Z",
        -37
    ],
    [
        "2023-06-09T00:15:00.000Z",
        149
    ],
    [
        "2023-06-09T05:00:00.000Z",
        91
    ],
    [
        "2023-06-09T10:41:00.000Z",
        166
    ],
    [
        "2023-06-09T17:35:00.000Z",
        -23
    ],
    [
        "2023-06-10T01:04:00.000Z",
        155
    ],
    [
        "2023-06-10T06:25:00.000Z",
        80
    ],
    [
        "2023-06-10T11:54:00.000Z",
        148
    ],
    [
        "2023-06-10T18:30:00.000Z",
        -7
    ],
    [
        "2023-06-11T01:51:00.000Z",
        164
    ],
    [
        "2023-06-11T07:45:00.000Z",
        61
    ],
    [
        "2023-06-11T13:17:00.000Z",
        132
    ],
    [
        "2023-06-11T19:25:00.000Z",
        11
    ],
    [
        "2023-06-12T02:34:00.000Z",
        174
    ],
    [
        "2023-06-12T08:54:00.000Z",
        39
    ],
    [
        "2023-06-12T14:45:00.000Z",
        123
    ],
    [
        "2023-06-12T20:18:00.000Z",
        29
    ],
    [
        "2023-06-13T03:15:00.000Z",
        183
    ],
    [
        "2023-06-13T09:53:00.000Z",
        16
    ],
    [
        "2023-06-13T16:09:00.000Z",
        121
    ],
    [
        "2023-06-13T21:09:00.000Z",
        48
    ],
    [
        "2023-06-14T03:54:00.000Z",
        190
    ],
    [
        "2023-06-14T10:45:00.000Z",
        -2
    ],
    [
        "2023-06-14T17:24:00.000Z",
        125
    ],
    [
        "2023-06-14T21:58:00.000Z",
        64
    ],
    [
        "2023-06-15T04:32:00.000Z",
        195
    ],
    [
        "2023-06-15T11:31:00.000Z",
        -16
    ],
    [
        "2023-06-15T18:28:00.000Z",
        131
    ],
    [
        "2023-06-15T22:47:00.000Z",
        78
    ],
    [
        "2023-06-16T05:09:00.000Z",
        196
    ],
    [
        "2023-06-16T12:13:00.000Z",
        -25
    ],
    [
        "2023-06-16T19:25:00.000Z",
        137
    ],
    [
        "2023-06-16T23:35:00.000Z",
        89
    ],
    [
        "2023-06-17T05:45:00.000Z",
        195
    ],
    [
        "2023-06-17T12:52:00.000Z",
        -29
    ],
    [
        "2023-06-17T20:15:00.000Z",
        141
    ],
    [
        "2023-06-18T00:21:00.000Z",
        96
    ],
    [
        "2023-06-18T06:22:00.000Z",
        192
    ],
    [
        "2023-06-18T13:31:00.000Z",
        -29
    ],
    [
        "2023-06-18T21:01:00.000Z",
        143
    ],
    [
        "2023-06-19T01:06:00.000Z",
        101
    ],
    [
        "2023-06-19T06:59:00.000Z",
        187
    ],
    [
        "2023-06-19T14:08:00.000Z",
        -27
    ],
    [
        "2023-06-19T21:44:00.000Z",
        142
    ],
    [
        "2023-06-20T01:51:00.000Z",
        103
    ],
    [
        "2023-06-20T07:37:00.000Z",
        181
    ],
    [
        "2023-06-20T14:46:00.000Z",
        -23
    ],
    [
        "2023-06-20T22:24:00.000Z",
        141
    ],
    [
        "2023-06-21T02:36:00.000Z",
        103
    ],
    [
        "2023-06-21T08:16:00.000Z",
        174
    ],
    [
        "2023-06-21T15:23:00.000Z",
        -18
    ],
    [
        "2023-06-21T23:02:00.000Z",
        140
    ],
    [
        "2023-06-22T03:25:00.000Z",
        101
    ],
    [
        "2023-06-22T08:56:00.000Z",
        165
    ],
    [
        "2023-06-22T16:01:00.000Z",
        -11
    ],
    [
        "2023-06-22T23:39:00.000Z",
        140
    ],
    [
        "2023-06-23T04:19:00.000Z",
        98
    ],
    [
        "2023-06-23T09:39:00.000Z",
        153
    ],
    [
        "2023-06-23T16:40:00.000Z",
        -2
    ],
    [
        "2023-06-24T00:15:00.000Z",
        142
    ],
    [
        "2023-06-24T05:21:00.000Z",
        93
    ],
    [
        "2023-06-24T10:28:00.000Z",
        140
    ],
    [
        "2023-06-24T17:19:00.000Z",
        9
    ],
    [
        "2023-06-25T00:49:00.000Z",
        146
    ],
    [
        "2023-06-25T06:29:00.000Z",
        84
    ],
    [
        "2023-06-25T11:27:00.000Z",
        126
    ],
    [
        "2023-06-25T18:00:00.000Z",
        23
    ],
    [
        "2023-06-26T01:23:00.000Z",
        152
    ],
    [
        "2023-06-26T07:35:00.000Z",
        71
    ],
    [
        "2023-06-26T12:41:00.000Z",
        114
    ],
    [
        "2023-06-26T18:44:00.000Z",
        38
    ],
    [
        "2023-06-27T01:57:00.000Z",
        160
    ],
    [
        "2023-06-27T08:33:00.000Z",
        53
    ],
    [
        "2023-06-27T14:10:00.000Z",
        107
    ],
    [
        "2023-06-27T19:31:00.000Z",
        53
    ],
    [
        "2023-06-28T02:32:00.000Z",
        169
    ],
    [
        "2023-06-28T09:24:00.000Z",
        33
    ],
    [
        "2023-06-28T15:42:00.000Z",
        109
    ],
    [
        "2023-06-28T20:21:00.000Z",
        68
    ],
    [
        "2023-06-29T03:08:00.000Z",
        179
    ],
    [
        "2023-06-29T10:09:00.000Z",
        12
    ],
    [
        "2023-06-29T17:02:00.000Z",
        116
    ],
    [
        "2023-06-29T21:12:00.000Z",
        81
    ],
    [
        "2023-06-30T03:46:00.000Z",
        188
    ],
    [
        "2023-06-30T10:53:00.000Z",
        -7
    ],
    [
        "2023-06-30T18:07:00.000Z",
        126
    ],
    [
        "2023-06-30T22:04:00.000Z",
        91
    ],
    [
        "2023-07-01T04:28:00.000Z",
        198
    ],
    [
        "2023-07-01T11:37:00.000Z",
        -25
    ],
    [
        "2023-07-01T19:01:00.000Z",
        134
    ],
    [
        "2023-07-01T22:55:00.000Z",
        97
    ],
    [
        "2023-07-02T05:13:00.000Z",
        205
    ],
    [
        "2023-07-02T12:22:00.000Z",
        -39
    ],
    [
        "2023-07-02T19:50:00.000Z",
        141
    ],
    [
        "2023-07-02T23:47:00.000Z",
        100
    ],
    [
        "2023-07-03T06:00:00.000Z",
        211
    ],
    [
        "2023-07-03T13:08:00.000Z",
        -48
    ],
    [
        "2023-07-03T20:36:00.000Z",
        146
    ],
    [
        "2023-07-04T00:39:00.000Z",
        98
    ],
    [
        "2023-07-04T06:51:00.000Z",
        213
    ],
    [
        "2023-07-04T13:55:00.000Z",
        -52
    ],
    [
        "2023-07-04T21:21:00.000Z",
        150
    ],
    [
        "2023-07-05T01:34:00.000Z",
        94
    ],
    [
        "2023-07-05T07:42:00.000Z",
        209
    ],
    [
        "2023-07-05T14:42:00.000Z",
        -50
    ],
    [
        "2023-07-05T22:04:00.000Z",
        154
    ],
    [
        "2023-07-06T02:32:00.000Z",
        89
    ],
    [
        "2023-07-06T08:36:00.000Z",
        200
    ],
    [
        "2023-07-06T15:29:00.000Z",
        -42
    ],
    [
        "2023-07-06T22:47:00.000Z",
        158
    ],
    [
        "2023-07-07T03:37:00.000Z",
        82
    ],
    [
        "2023-07-07T09:33:00.000Z",
        184
    ],
    [
        "2023-07-07T16:16:00.000Z",
        -27
    ],
    [
        "2023-07-07T23:30:00.000Z",
        164
    ],
    [
        "2023-07-08T04:48:00.000Z",
        73
    ],
    [
        "2023-07-08T10:36:00.000Z",
        163
    ],
    [
        "2023-07-08T17:04:00.000Z",
        -8
    ],
    [
        "2023-07-09T00:14:00.000Z",
        171
    ],
    [
        "2023-07-09T06:05:00.000Z",
        60
    ],
    [
        "2023-07-09T11:48:00.000Z",
        142
    ],
    [
        "2023-07-09T17:53:00.000Z",
        15
    ],
    [
        "2023-07-10T00:59:00.000Z",
        179
    ],
    [
        "2023-07-10T07:22:00.000Z",
        44
    ],
    [
        "2023-07-10T13:13:00.000Z",
        125
    ],
    [
        "2023-07-10T18:45:00.000Z",
        38
    ],
    [
        "2023-07-11T01:44:00.000Z",
        186
    ],
    [
        "2023-07-11T08:34:00.000Z",
        27
    ],
    [
        "2023-07-11T14:50:00.000Z",
        118
    ],
    [
        "2023-07-11T19:41:00.000Z",
        60
    ],
    [
        "2023-07-12T02:30:00.000Z",
        191
    ],
    [
        "2023-07-12T09:36:00.000Z",
        10
    ],
    [
        "2023-07-12T16:21:00.000Z",
        121
    ],
    [
        "2023-07-12T20:41:00.000Z",
        78
    ],
    [
        "2023-07-13T03:16:00.000Z",
        194
    ],
    [
        "2023-07-13T10:30:00.000Z",
        -3
    ],
    [
        "2023-07-13T17:34:00.000Z",
        130
    ],
    [
        "2023-07-13T21:40:00.000Z",
        90
    ],
    [
        "2023-07-14T04:00:00.000Z",
        195
    ],
    [
        "2023-07-14T11:18:00.000Z",
        -12
    ],
    [
        "2023-07-14T18:32:00.000Z",
        138
    ],
    [
        "2023-07-14T22:36:00.000Z",
        98
    ],
    [
        "2023-07-15T04:44:00.000Z",
        195
    ],
    [
        "2023-07-15T12:00:00.000Z",
        -17
    ],
    [
        "2023-07-15T19:20:00.000Z",
        144
    ],
    [
        "2023-07-15T23:26:00.000Z",
        101
    ],
    [
        "2023-07-16T05:26:00.000Z",
        193
    ],
    [
        "2023-07-16T12:39:00.000Z",
        -19
    ],
    [
        "2023-07-16T20:02:00.000Z",
        146
    ],
    [
        "2023-07-17T00:12:00.000Z",
        101
    ],
    [
        "2023-07-17T06:06:00.000Z",
        191
    ],
    [
        "2023-07-17T13:15:00.000Z",
        -19
    ],
    [
        "2023-07-17T20:39:00.000Z",
        147
    ],
    [
        "2023-07-18T00:54:00.000Z",
        100
    ],
    [
        "2023-07-18T06:45:00.000Z",
        188
    ],
    [
        "2023-07-18T13:49:00.000Z",
        -17
    ],
    [
        "2023-07-18T21:12:00.000Z",
        146
    ],
    [
        "2023-07-19T01:34:00.000Z",
        97
    ],
    [
        "2023-07-19T07:22:00.000Z",
        183
    ],
    [
        "2023-07-19T14:22:00.000Z",
        -14
    ],
    [
        "2023-07-19T21:43:00.000Z",
        145
    ],
    [
        "2023-07-20T02:14:00.000Z",
        94
    ],
    [
        "2023-07-20T08:00:00.000Z",
        176
    ],
    [
        "2023-07-20T14:53:00.000Z",
        -9
    ],
    [
        "2023-07-20T22:11:00.000Z",
        146
    ],
    [
        "2023-07-21T02:55:00.000Z",
        90
    ],
    [
        "2023-07-21T08:37:00.000Z",
        167
    ],
    [
        "2023-07-21T15:24:00.000Z",
        -2
    ],
    [
        "2023-07-21T22:39:00.000Z",
        148
    ],
    [
        "2023-07-22T03:40:00.000Z",
        85
    ],
    [
        "2023-07-22T09:18:00.000Z",
        155
    ],
    [
        "2023-07-22T15:55:00.000Z",
        9
    ],
    [
        "2023-07-22T23:07:00.000Z",
        152
    ],
    [
        "2023-07-23T04:31:00.000Z",
        80
    ],
    [
        "2023-07-23T10:03:00.000Z",
        141
    ],
    [
        "2023-07-23T16:27:00.000Z",
        23
    ],
    [
        "2023-07-23T23:38:00.000Z",
        157
    ],
    [
        "2023-07-24T05:29:00.000Z",
        72
    ],
    [
        "2023-07-24T10:59:00.000Z",
        127
    ],
    [
        "2023-07-24T17:02:00.000Z",
        40
    ],
    [
        "2023-07-25T00:11:00.000Z",
        162
    ],
    [
        "2023-07-25T06:33:00.000Z",
        61
    ],
    [
        "2023-07-25T12:13:00.000Z",
        114
    ],
    [
        "2023-07-25T17:42:00.000Z",
        58
    ],
    [
        "2023-07-26T00:48:00.000Z",
        169
    ],
    [
        "2023-07-26T07:38:00.000Z",
        46
    ],
    [
        "2023-07-26T13:53:00.000Z",
        108
    ],
    [
        "2023-07-26T18:32:00.000Z",
        76
    ],
    [
        "2023-07-27T01:30:00.000Z",
        176
    ],
    [
        "2023-07-27T08:39:00.000Z",
        28
    ],
    [
        "2023-07-27T15:39:00.000Z",
        112
    ],
    [
        "2023-07-27T19:33:00.000Z",
        91
    ],
    [
        "2023-07-28T02:17:00.000Z",
        184
    ],
    [
        "2023-07-28T09:35:00.000Z",
        10
    ],
    [
        "2023-07-28T17:01:00.000Z",
        122
    ],
    [
        "2023-07-28T20:40:00.000Z",
        100
    ],
    [
        "2023-07-29T03:09:00.000Z",
        193
    ],
    [
        "2023-07-29T10:27:00.000Z",
        -9
    ],
    [
        "2023-07-29T17:59:00.000Z",
        132
    ],
    [
        "2023-07-29T21:44:00.000Z",
        103
    ],
    [
        "2023-07-30T04:02:00.000Z",
        203
    ],
    [
        "2023-07-30T11:17:00.000Z",
        -25
    ],
    [
        "2023-07-30T18:45:00.000Z",
        141
    ],
    [
        "2023-07-30T22:42:00.000Z",
        101
    ],
    [
        "2023-07-31T04:56:00.000Z",
        211
    ],
    [
        "2023-07-31T12:05:00.000Z",
        -37
    ],
    [
        "2023-07-31T19:27:00.000Z",
        148
    ],
    [
        "2023-07-31T23:37:00.000Z",
        95
    ],
    [
        "2023-08-01T05:50:00.000Z",
        216
    ],
    [
        "2023-08-01T12:51:00.000Z",
        -44
    ],
    [
        "2023-08-01T20:06:00.000Z",
        153
    ],
    [
        "2023-08-02T00:31:00.000Z",
        86
    ],
    [
        "2023-08-02T06:44:00.000Z",
        217
    ],
    [
        "2023-08-02T13:36:00.000Z",
        -44
    ],
    [
        "2023-08-02T20:44:00.000Z",
        159
    ],
    [
        "2023-08-03T01:25:00.000Z",
        76
    ],
    [
        "2023-08-03T07:37:00.000Z",
        211
    ],
    [
        "2023-08-03T14:20:00.000Z",
        -37
    ],
    [
        "2023-08-03T21:22:00.000Z",
        166
    ],
    [
        "2023-08-04T02:22:00.000Z",
        65
    ],
    [
        "2023-08-04T08:32:00.000Z",
        198
    ],
    [
        "2023-08-04T15:02:00.000Z",
        -23
    ],
    [
        "2023-08-04T22:01:00.000Z",
        172
    ],
    [
        "2023-08-05T03:22:00.000Z",
        55
    ],
    [
        "2023-08-05T09:29:00.000Z",
        179
    ],
    [
        "2023-08-05T15:45:00.000Z",
        -4
    ],
    [
        "2023-08-05T22:40:00.000Z",
        178
    ],
    [
        "2023-08-06T04:27:00.000Z",
        47
    ],
    [
        "2023-08-06T10:32:00.000Z",
        157
    ],
    [
        "2023-08-06T16:29:00.000Z",
        20
    ],
    [
        "2023-08-06T23:21:00.000Z",
        183
    ],
    [
        "2023-08-07T05:37:00.000Z",
        38
    ],
    [
        "2023-08-07T11:46:00.000Z",
        137
    ],
    [
        "2023-08-07T17:16:00.000Z",
        45
    ],
    [
        "2023-08-08T00:06:00.000Z",
        186
    ],
    [
        "2023-08-08T06:51:00.000Z",
        29
    ],
    [
        "2023-08-08T13:17:00.000Z",
        124
    ],
    [
        "2023-08-08T18:11:00.000Z",
        69
    ],
    [
        "2023-08-09T00:54:00.000Z",
        187
    ],
    [
        "2023-08-09T08:04:00.000Z",
        20
    ],
    [
        "2023-08-09T14:59:00.000Z",
        123
    ],
    [
        "2023-08-09T19:18:00.000Z",
        87
    ],
    [
        "2023-08-10T01:47:00.000Z",
        187
    ],
    [
        "2023-08-10T09:11:00.000Z",
        11
    ],
    [
        "2023-08-10T16:26:00.000Z",
        130
    ],
    [
        "2023-08-10T20:31:00.000Z",
        98
    ],
    [
        "2023-08-11T02:43:00.000Z",
        186
    ],
    [
        "2023-08-11T10:09:00.000Z",
        3
    ],
    [
        "2023-08-11T17:28:00.000Z",
        140
    ],
    [
        "2023-08-11T21:38:00.000Z",
        102
    ],
    [
        "2023-08-12T03:37:00.000Z",
        186
    ],
    [
        "2023-08-12T10:59:00.000Z",
        -3
    ],
    [
        "2023-08-12T18:16:00.000Z",
        146
    ],
    [
        "2023-08-12T22:34:00.000Z",
        101
    ],
    [
        "2023-08-13T04:27:00.000Z",
        187
    ],
    [
        "2023-08-13T11:42:00.000Z",
        -6
    ],
    [
        "2023-08-13T18:56:00.000Z",
        149
    ],
    [
        "2023-08-13T23:21:00.000Z",
        97
    ],
    [
        "2023-08-14T05:13:00.000Z",
        187
    ],
    [
        "2023-08-14T12:19:00.000Z",
        -7
    ],
    [
        "2023-08-14T19:30:00.000Z",
        150
    ],
    [
        "2023-08-15T00:02:00.000Z",
        91
    ],
    [
        "2023-08-15T05:54:00.000Z",
        186
    ],
    [
        "2023-08-15T12:52:00.000Z",
        -7
    ],
    [
        "2023-08-15T19:59:00.000Z",
        149
    ],
    [
        "2023-08-16T00:40:00.000Z",
        86
    ],
    [
        "2023-08-16T06:33:00.000Z",
        184
    ],
    [
        "2023-08-16T13:22:00.000Z",
        -5
    ],
    [
        "2023-08-16T20:25:00.000Z",
        150
    ],
    [
        "2023-08-17T01:15:00.000Z",
        80
    ],
    [
        "2023-08-17T07:10:00.000Z",
        179
    ],
    [
        "2023-08-17T13:50:00.000Z",
        -1
    ],
    [
        "2023-08-17T20:49:00.000Z",
        151
    ],
    [
        "2023-08-18T01:50:00.000Z",
        74
    ],
    [
        "2023-08-18T07:46:00.000Z",
        172
    ],
    [
        "2023-08-18T14:16:00.000Z",
        6
    ],
    [
        "2023-08-18T21:13:00.000Z",
        154
    ],
    [
        "2023-08-19T02:27:00.000Z",
        68
    ],
    [
        "2023-08-19T08:24:00.000Z",
        163
    ],
    [
        "2023-08-19T14:43:00.000Z",
        16
    ],
    [
        "2023-08-19T21:37:00.000Z",
        158
    ],
    [
        "2023-08-20T03:06:00.000Z",
        62
    ],
    [
        "2023-08-20T09:05:00.000Z",
        152
    ],
    [
        "2023-08-20T15:11:00.000Z",
        30
    ],
    [
        "2023-08-20T22:02:00.000Z",
        162
    ],
    [
        "2023-08-21T03:50:00.000Z",
        56
    ],
    [
        "2023-08-21T09:52:00.000Z",
        140
    ],
    [
        "2023-08-21T15:40:00.000Z",
        45
    ],
    [
        "2023-08-21T22:31:00.000Z",
        166
    ],
    [
        "2023-08-22T04:40:00.000Z",
        49
    ],
    [
        "2023-08-22T10:50:00.000Z",
        127
    ],
    [
        "2023-08-22T16:13:00.000Z",
        63
    ],
    [
        "2023-08-22T23:04:00.000Z",
        169
    ],
    [
        "2023-08-23T05:38:00.000Z",
        42
    ],
    [
        "2023-08-23T12:10:00.000Z",
        117
    ],
    [
        "2023-08-23T16:53:00.000Z",
        81
    ],
    [
        "2023-08-23T23:45:00.000Z",
        173
    ],
    [
        "2023-08-24T06:46:00.000Z",
        33
    ],
    [
        "2023-08-24T13:57:00.000Z",
        114
    ],
    [
        "2023-08-24T17:49:00.000Z",
        97
    ],
    [
        "2023-08-25T00:37:00.000Z",
        176
    ],
    [
        "2023-08-25T07:58:00.000Z",
        20
    ],
    [
        "2023-08-25T15:42:00.000Z",
        121
    ],
    [
        "2023-08-25T19:10:00.000Z",
        107
    ],
    [
        "2023-08-26T01:39:00.000Z",
        182
    ],
    [
        "2023-08-26T09:04:00.000Z",
        5
    ],
    [
        "2023-08-26T16:49:00.000Z",
        131
    ],
    [
        "2023-08-26T20:32:00.000Z",
        108
    ],
    [
        "2023-08-27T02:45:00.000Z",
        190
    ],
    [
        "2023-08-27T10:03:00.000Z",
        -9
    ],
    [
        "2023-08-27T17:35:00.000Z",
        140
    ],
    [
        "2023-08-27T21:41:00.000Z",
        102
    ],
    [
        "2023-08-28T03:48:00.000Z",
        198
    ],
    [
        "2023-08-28T10:55:00.000Z",
        -22
    ],
    [
        "2023-08-28T18:14:00.000Z",
        148
    ],
    [
        "2023-08-28T22:38:00.000Z",
        90
    ],
    [
        "2023-08-29T04:47:00.000Z",
        206
    ],
    [
        "2023-08-29T11:42:00.000Z",
        -29
    ],
    [
        "2023-08-29T18:50:00.000Z",
        155
    ],
    [
        "2023-08-29T23:32:00.000Z",
        75
    ],
    [
        "2023-08-30T05:44:00.000Z",
        210
    ],
    [
        "2023-08-30T12:27:00.000Z",
        -30
    ],
    [
        "2023-08-30T19:25:00.000Z",
        162
    ],
    [
        "2023-08-31T00:23:00.000Z",
        60
    ],
    [
        "2023-08-31T06:39:00.000Z",
        207
    ],
    [
        "2023-08-31T13:08:00.000Z",
        -25
    ],
    [
        "2023-08-31T20:00:00.000Z",
        171
    ],
    [
        "2023-09-01T01:15:00.000Z",
        45
    ],
    [
        "2023-09-01T07:33:00.000Z",
        198
    ],
    [
        "2023-09-01T13:49:00.000Z",
        -12
    ],
    [
        "2023-09-01T20:35:00.000Z",
        178
    ],
    [
        "2023-09-02T02:09:00.000Z",
        32
    ],
    [
        "2023-09-02T08:29:00.000Z",
        184
    ],
    [
        "2023-09-02T14:29:00.000Z",
        6
    ],
    [
        "2023-09-02T21:10:00.000Z",
        185
    ],
    [
        "2023-09-03T03:03:00.000Z",
        23
    ],
    [
        "2023-09-03T09:28:00.000Z",
        167
    ],
    [
        "2023-09-03T15:10:00.000Z",
        29
    ],
    [
        "2023-09-03T21:48:00.000Z",
        188
    ],
    [
        "2023-09-04T04:01:00.000Z",
        18
    ],
    [
        "2023-09-04T10:33:00.000Z",
        150
    ],
    [
        "2023-09-04T15:53:00.000Z",
        52
    ],
    [
        "2023-09-04T22:27:00.000Z",
        188
    ],
    [
        "2023-09-05T05:04:00.000Z",
        16
    ],
    [
        "2023-09-05T11:49:00.000Z",
        136
    ],
    [
        "2023-09-05T16:43:00.000Z",
        75
    ],
    [
        "2023-09-05T23:12:00.000Z",
        184
    ],
    [
        "2023-09-06T06:13:00.000Z",
        16
    ],
    [
        "2023-09-06T13:22:00.000Z",
        129
    ],
    [
        "2023-09-06T17:47:00.000Z",
        93
    ],
    [
        "2023-09-07T00:04:00.000Z",
        178
    ],
    [
        "2023-09-07T07:27:00.000Z",
        16
    ],
    [
        "2023-09-07T14:59:00.000Z",
        132
    ],
    [
        "2023-09-07T19:10:00.000Z",
        104
    ],
    [
        "2023-09-08T01:06:00.000Z",
        173
    ],
    [
        "2023-09-08T08:38:00.000Z",
        14
    ],
    [
        "2023-09-08T16:12:00.000Z",
        140
    ],
    [
        "2023-09-08T20:32:00.000Z",
        105
    ],
    [
        "2023-09-09T02:12:00.000Z",
        170
    ],
    [
        "2023-09-09T09:39:00.000Z",
        10
    ],
    [
        "2023-09-09T17:03:00.000Z",
        146
    ],
    [
        "2023-09-09T21:37:00.000Z",
        100
    ],
    [
        "2023-09-10T03:16:00.000Z",
        170
    ],
    [
        "2023-09-10T10:30:00.000Z",
        7
    ],
    [
        "2023-09-10T17:43:00.000Z",
        150
    ],
    [
        "2023-09-10T22:27:00.000Z",
        91
    ],
    [
        "2023-09-11T04:10:00.000Z",
        173
    ],
    [
        "2023-09-11T11:11:00.000Z",
        5
    ],
    [
        "2023-09-11T18:16:00.000Z",
        152
    ],
    [
        "2023-09-11T23:09:00.000Z",
        82
    ],
    [
        "2023-09-12T04:58:00.000Z",
        174
    ],
    [
        "2023-09-12T11:46:00.000Z",
        4
    ],
    [
        "2023-09-12T18:44:00.000Z",
        153
    ],
    [
        "2023-09-12T23:47:00.000Z",
        73
    ],
    [
        "2023-09-13T05:40:00.000Z",
        174
    ],
    [
        "2023-09-13T12:17:00.000Z",
        7
    ],
    [
        "2023-09-13T19:08:00.000Z",
        154
    ],
    [
        "2023-09-14T00:21:00.000Z",
        64
    ],
    [
        "2023-09-14T06:20:00.000Z",
        172
    ],
    [
        "2023-09-14T12:44:00.000Z",
        11
    ],
    [
        "2023-09-14T19:30:00.000Z",
        156
    ],
    [
        "2023-09-15T00:54:00.000Z",
        55
    ],
    [
        "2023-09-15T06:58:00.000Z",
        167
    ],
    [
        "2023-09-15T13:10:00.000Z",
        19
    ],
    [
        "2023-09-15T19:51:00.000Z",
        160
    ],
    [
        "2023-09-16T01:27:00.000Z",
        47
    ],
    [
        "2023-09-16T07:38:00.000Z",
        161
    ],
    [
        "2023-09-16T13:35:00.000Z",
        29
    ],
    [
        "2023-09-16T20:13:00.000Z",
        165
    ],
    [
        "2023-09-17T02:01:00.000Z",
        39
    ],
    [
        "2023-09-17T08:19:00.000Z",
        154
    ],
    [
        "2023-09-17T14:01:00.000Z",
        41
    ],
    [
        "2023-09-17T20:36:00.000Z",
        169
    ],
    [
        "2023-09-18T02:37:00.000Z",
        32
    ],
    [
        "2023-09-18T09:04:00.000Z",
        145
    ],
    [
        "2023-09-18T14:29:00.000Z",
        55
    ],
    [
        "2023-09-18T21:02:00.000Z",
        172
    ],
    [
        "2023-09-19T03:18:00.000Z",
        26
    ],
    [
        "2023-09-19T09:56:00.000Z",
        136
    ],
    [
        "2023-09-19T15:00:00.000Z",
        71
    ],
    [
        "2023-09-19T21:31:00.000Z",
        173
    ],
    [
        "2023-09-20T04:05:00.000Z",
        21
    ],
    [
        "2023-09-20T11:01:00.000Z",
        127
    ],
    [
        "2023-09-20T15:35:00.000Z",
        86
    ],
    [
        "2023-09-20T22:08:00.000Z",
        173
    ],
    [
        "2023-09-21T05:01:00.000Z",
        18
    ],
    [
        "2023-09-21T12:25:00.000Z",
        122
    ],
    [
        "2023-09-21T16:22:00.000Z",
        100
    ],
    [
        "2023-09-21T22:55:00.000Z",
        173
    ],
    [
        "2023-09-22T06:09:00.000Z",
        15
    ],
    [
        "2023-09-22T14:07:00.000Z",
        123
    ],
    [
        "2023-09-22T17:34:00.000Z",
        110
    ],
    [
        "2023-09-22T23:58:00.000Z",
        172
    ],
    [
        "2023-09-23T07:25:00.000Z",
        9
    ],
    [
        "2023-09-23T15:28:00.000Z",
        130
    ],
    [
        "2023-09-23T19:14:00.000Z",
        112
    ],
    [
        "2023-09-24T01:14:00.000Z",
        173
    ],
    [
        "2023-09-24T08:36:00.000Z",
        0
    ],
    [
        "2023-09-24T16:20:00.000Z",
        139
    ],
    [
        "2023-09-24T20:38:00.000Z",
        103
    ],
    [
        "2023-09-25T02:30:00.000Z",
        178
    ],
    [
        "2023-09-25T09:36:00.000Z",
        -9
    ],
    [
        "2023-09-25T16:59:00.000Z",
        148
    ],
    [
        "2023-09-25T21:42:00.000Z",
        87
    ],
    [
        "2023-09-26T03:39:00.000Z",
        184
    ],
    [
        "2023-09-26T10:27:00.000Z",
        -14
    ],
    [
        "2023-09-26T17:34:00.000Z",
        157
    ],
    [
        "2023-09-26T22:36:00.000Z",
        67
    ],
    [
        "2023-09-27T04:42:00.000Z",
        189
    ],
    [
        "2023-09-27T11:13:00.000Z",
        -14
    ],
    [
        "2023-09-27T18:07:00.000Z",
        166
    ],
    [
        "2023-09-27T23:27:00.000Z",
        47
    ],
    [
        "2023-09-28T05:40:00.000Z",
        190
    ],
    [
        "2023-09-28T11:55:00.000Z",
        -8
    ],
    [
        "2023-09-28T18:40:00.000Z",
        176
    ],
    [
        "2023-09-29T00:16:00.000Z",
        27
    ],
    [
        "2023-09-29T06:37:00.000Z",
        185
    ],
    [
        "2023-09-29T12:35:00.000Z",
        4
    ],
    [
        "2023-09-29T19:13:00.000Z",
        185
    ],
    [
        "2023-09-30T01:04:00.000Z",
        11
    ],
    [
        "2023-09-30T07:33:00.000Z",
        177
    ],
    [
        "2023-09-30T13:14:00.000Z",
        21
    ],
    [
        "2023-09-30T19:46:00.000Z",
        191
    ],
    [
        "2023-10-01T01:53:00.000Z",
        -1
    ],
    [
        "2023-10-01T08:31:00.000Z",
        167
    ],
    [
        "2023-10-01T13:54:00.000Z",
        41
    ],
    [
        "2023-10-01T20:20:00.000Z",
        194
    ],
    [
        "2023-10-02T02:43:00.000Z",
        -6
    ],
    [
        "2023-10-02T09:31:00.000Z",
        156
    ],
    [
        "2023-10-02T14:36:00.000Z",
        62
    ],
    [
        "2023-10-02T20:57:00.000Z",
        192
    ],
    [
        "2023-10-03T03:34:00.000Z",
        -6
    ],
    [
        "2023-10-03T10:37:00.000Z",
        145
    ],
    [
        "2023-10-03T15:22:00.000Z",
        81
    ],
    [
        "2023-10-03T21:36:00.000Z",
        186
    ],
    [
        "2023-10-04T04:29:00.000Z",
        -2
    ],
    [
        "2023-10-04T11:52:00.000Z",
        138
    ],
    [
        "2023-10-04T16:17:00.000Z",
        97
    ],
    [
        "2023-10-04T22:21:00.000Z",
        177
    ],
    [
        "2023-10-05T05:32:00.000Z",
        5
    ],
    [
        "2023-10-05T13:17:00.000Z",
        136
    ],
    [
        "2023-10-05T17:33:00.000Z",
        108
    ],
    [
        "2023-10-05T23:15:00.000Z",
        166
    ],
    [
        "2023-10-06T06:42:00.000Z",
        12
    ],
    [
        "2023-10-06T14:39:00.000Z",
        139
    ],
    [
        "2023-10-06T19:05:00.000Z",
        109
    ],
    [
        "2023-10-07T00:22:00.000Z",
        157
    ],
    [
        "2023-10-07T07:53:00.000Z",
        15
    ],
    [
        "2023-10-07T15:40:00.000Z",
        144
    ],
    [
        "2023-10-07T20:23:00.000Z",
        102
    ],
    [
        "2023-10-08T01:37:00.000Z",
        152
    ],
    [
        "2023-10-08T08:56:00.000Z",
        15
    ],
    [
        "2023-10-08T16:24:00.000Z",
        148
    ],
    [
        "2023-10-08T21:23:00.000Z",
        91
    ],
    [
        "2023-10-09T02:46:00.000Z",
        152
    ],
    [
        "2023-10-09T09:46:00.000Z",
        14
    ],
    [
        "2023-10-09T16:58:00.000Z",
        151
    ],
    [
        "2023-10-09T22:10:00.000Z",
        78
    ],
    [
        "2023-10-10T03:46:00.000Z",
        154
    ],
    [
        "2023-10-10T10:27:00.000Z",
        15
    ],
    [
        "2023-10-10T17:26:00.000Z",
        154
    ],
    [
        "2023-10-10T22:50:00.000Z",
        64
    ],
    [
        "2023-10-11T04:37:00.000Z",
        155
    ],
    [
        "2023-10-11T11:01:00.000Z",
        18
    ],
    [
        "2023-10-11T17:50:00.000Z",
        158
    ],
    [
        "2023-10-11T23:27:00.000Z",
        52
    ],
    [
        "2023-10-12T05:24:00.000Z",
        155
    ],
    [
        "2023-10-12T11:31:00.000Z",
        24
    ],
    [
        "2023-10-12T18:11:00.000Z",
        162
    ],
    [
        "2023-10-13T00:00:00.000Z",
        39
    ],
    [
        "2023-10-13T06:08:00.000Z",
        154
    ],
    [
        "2023-10-13T11:59:00.000Z",
        32
    ],
    [
        "2023-10-13T18:32:00.000Z",
        167
    ],
    [
        "2023-10-14T00:32:00.000Z",
        28
    ],
    [
        "2023-10-14T06:51:00.000Z",
        151
    ],
    [
        "2023-10-14T12:26:00.000Z",
        42
    ],
    [
        "2023-10-14T18:54:00.000Z",
        173
    ],
    [
        "2023-10-15T01:04:00.000Z",
        17
    ],
    [
        "2023-10-15T07:36:00.000Z",
        148
    ],
    [
        "2023-10-15T12:54:00.000Z",
        54
    ],
    [
        "2023-10-15T19:18:00.000Z",
        177
    ],
    [
        "2023-10-16T01:38:00.000Z",
        8
    ],
    [
        "2023-10-16T08:23:00.000Z",
        144
    ],
    [
        "2023-10-16T13:23:00.000Z",
        67
    ],
    [
        "2023-10-16T19:44:00.000Z",
        180
    ],
    [
        "2023-10-17T02:14:00.000Z",
        0
    ],
    [
        "2023-10-17T09:13:00.000Z",
        140
    ],
    [
        "2023-10-17T13:55:00.000Z",
        80
    ],
    [
        "2023-10-17T20:13:00.000Z",
        181
    ],
    [
        "2023-10-18T02:55:00.000Z",
        -4
    ],
    [
        "2023-10-18T10:10:00.000Z",
        136
    ],
    [
        "2023-10-18T14:31:00.000Z",
        92
    ],
    [
        "2023-10-18T20:47:00.000Z",
        180
    ],
    [
        "2023-10-19T03:42:00.000Z",
        -5
    ],
    [
        "2023-10-19T11:17:00.000Z",
        131
    ],
    [
        "2023-10-19T15:14:00.000Z",
        103
    ],
    [
        "2023-10-19T21:29:00.000Z",
        177
    ],
    [
        "2023-10-20T04:39:00.000Z",
        -5
    ],
    [
        "2023-10-20T12:34:00.000Z",
        130
    ],
    [
        "2023-10-20T16:13:00.000Z",
        111
    ],
    [
        "2023-10-20T22:24:00.000Z",
        172
    ],
    [
        "2023-10-21T05:44:00.000Z",
        -3
    ],
    [
        "2023-10-21T13:52:00.000Z",
        133
    ],
    [
        "2023-10-21T17:43:00.000Z",
        114
    ],
    [
        "2023-10-21T23:35:00.000Z",
        166
    ],
    [
        "2023-10-22T06:56:00.000Z",
        -3
    ],
    [
        "2023-10-22T14:53:00.000Z",
        140
    ],
    [
        "2023-10-22T19:24:00.000Z",
        105
    ],
    [
        "2023-10-23T00:58:00.000Z",
        162
    ],
    [
        "2023-10-23T08:04:00.000Z",
        -4
    ],
    [
        "2023-10-23T15:38:00.000Z",
        149
    ],
    [
        "2023-10-23T20:41:00.000Z",
        88
    ],
    [
        "2023-10-24T02:19:00.000Z",
        161
    ],
    [
        "2023-10-24T09:03:00.000Z",
        -3
    ],
    [
        "2023-10-24T16:15:00.000Z",
        159
    ],
    [
        "2023-10-24T21:41:00.000Z",
        65
    ],
    [
        "2023-10-25T03:33:00.000Z",
        162
    ],
    [
        "2023-10-25T09:53:00.000Z",
        1
    ],
    [
        "2023-10-25T16:50:00.000Z",
        170
    ],
    [
        "2023-10-25T22:33:00.000Z",
        41
    ],
    [
        "2023-10-26T04:40:00.000Z",
        164
    ],
    [
        "2023-10-26T10:38:00.000Z",
        10
    ],
    [
        "2023-10-26T17:23:00.000Z",
        182
    ],
    [
        "2023-10-26T23:21:00.000Z",
        17
    ],
    [
        "2023-10-27T05:42:00.000Z",
        163
    ],
    [
        "2023-10-27T11:19:00.000Z",
        23
    ],
    [
        "2023-10-27T17:55:00.000Z",
        191
    ],
    [
        "2023-10-28T00:08:00.000Z",
        -3
    ],
    [
        "2023-10-28T06:42:00.000Z",
        162
    ],
    [
        "2023-10-28T12:00:00.000Z",
        39
    ],
    [
        "2023-10-28T18:28:00.000Z",
        198
    ],
    [
        "2023-10-29T00:53:00.000Z",
        -17
    ],
    [
        "2023-10-29T07:40:00.000Z",
        158
    ],
    [
        "2023-10-29T12:41:00.000Z",
        56
    ],
    [
        "2023-10-29T19:02:00.000Z",
        201
    ],
    [
        "2023-10-30T01:38:00.000Z",
        -25
    ],
    [
        "2023-10-30T08:38:00.000Z",
        154
    ],
    [
        "2023-10-30T13:23:00.000Z",
        72
    ],
    [
        "2023-10-30T19:37:00.000Z",
        199
    ],
    [
        "2023-10-31T02:23:00.000Z",
        -26
    ],
    [
        "2023-10-31T09:37:00.000Z",
        150
    ],
    [
        "2023-10-31T14:08:00.000Z",
        87
    ],
    [
        "2023-10-31T20:13:00.000Z",
        193
    ],
    [
        "2023-11-01T03:09:00.000Z",
        -22
    ],
    [
        "2023-11-01T10:38:00.000Z",
        145
    ],
    [
        "2023-11-01T14:58:00.000Z",
        99
    ],
    [
        "2023-11-01T20:53:00.000Z",
        183
    ],
    [
        "2023-11-02T03:59:00.000Z",
        -14
    ],
    [
        "2023-11-02T11:44:00.000Z",
        142
    ],
    [
        "2023-11-02T15:57:00.000Z",
        108
    ],
    [
        "2023-11-02T21:38:00.000Z",
        171
    ],
    [
        "2023-11-03T04:53:00.000Z",
        -4
    ],
    [
        "2023-11-03T12:53:00.000Z",
        141
    ],
    [
        "2023-11-03T17:14:00.000Z",
        112
    ],
    [
        "2023-11-03T22:31:00.000Z",
        158
    ],
    [
        "2023-11-04T05:54:00.000Z",
        5
    ],
    [
        "2023-11-04T13:58:00.000Z",
        142
    ],
    [
        "2023-11-04T18:41:00.000Z",
        108
    ],
    [
        "2023-11-04T23:35:00.000Z",
        147
    ],
    [
        "2023-11-05T06:58:00.000Z",
        13
    ],
    [
        "2023-11-05T14:51:00.000Z",
        145
    ],
    [
        "2023-11-05T19:56:00.000Z",
        98
    ],
    [
        "2023-11-06T00:49:00.000Z",
        138
    ],
    [
        "2023-11-06T07:57:00.000Z",
        18
    ],
    [
        "2023-11-06T15:30:00.000Z",
        148
    ],
    [
        "2023-11-06T20:56:00.000Z",
        84
    ],
    [
        "2023-11-07T02:04:00.000Z",
        134
    ],
    [
        "2023-11-07T08:48:00.000Z",
        23
    ],
    [
        "2023-11-07T16:01:00.000Z",
        153
    ],
    [
        "2023-11-07T21:45:00.000Z",
        67
    ],
    [
        "2023-11-08T03:13:00.000Z",
        133
    ],
    [
        "2023-11-08T09:30:00.000Z",
        28
    ],
    [
        "2023-11-08T16:27:00.000Z",
        159
    ],
    [
        "2023-11-08T22:26:00.000Z",
        51
    ],
    [
        "2023-11-09T04:14:00.000Z",
        134
    ],
    [
        "2023-11-09T10:07:00.000Z",
        36
    ],
    [
        "2023-11-09T16:50:00.000Z",
        166
    ],
    [
        "2023-11-09T23:03:00.000Z",
        34
    ],
    [
        "2023-11-10T05:09:00.000Z",
        135
    ],
    [
        "2023-11-10T10:40:00.000Z",
        45
    ],
    [
        "2023-11-10T17:14:00.000Z",
        173
    ],
    [
        "2023-11-10T23:36:00.000Z",
        19
    ],
    [
        "2023-11-11T06:01:00.000Z",
        137
    ],
    [
        "2023-11-11T11:12:00.000Z",
        56
    ],
    [
        "2023-11-11T17:38:00.000Z",
        179
    ],
    [
        "2023-11-12T00:09:00.000Z",
        5
    ],
    [
        "2023-11-12T06:50:00.000Z",
        139
    ],
    [
        "2023-11-12T11:44:00.000Z",
        68
    ],
    [
        "2023-11-12T18:04:00.000Z",
        185
    ],
    [
        "2023-11-13T00:43:00.000Z",
        -8
    ],
    [
        "2023-11-13T07:40:00.000Z",
        141
    ],
    [
        "2023-11-13T12:17:00.000Z",
        79
    ],
    [
        "2023-11-13T18:32:00.000Z",
        190
    ],
    [
        "2023-11-14T01:18:00.000Z",
        -17
    ],
    [
        "2023-11-14T08:30:00.000Z",
        141
    ],
    [
        "2023-11-14T12:52:00.000Z",
        89
    ],
    [
        "2023-11-14T19:04:00.000Z",
        192
    ],
    [
        "2023-11-15T01:57:00.000Z",
        -24
    ],
    [
        "2023-11-15T09:22:00.000Z",
        141
    ],
    [
        "2023-11-15T13:31:00.000Z",
        98
    ],
    [
        "2023-11-15T19:41:00.000Z",
        192
    ],
    [
        "2023-11-16T02:41:00.000Z",
        -27
    ],
    [
        "2023-11-16T10:17:00.000Z",
        140
    ],
    [
        "2023-11-16T14:15:00.000Z",
        104
    ],
    [
        "2023-11-16T20:23:00.000Z",
        189
    ],
    [
        "2023-11-17T03:29:00.000Z",
        -27
    ],
    [
        "2023-11-17T11:16:00.000Z",
        139
    ],
    [
        "2023-11-17T15:08:00.000Z",
        109
    ],
    [
        "2023-11-17T21:12:00.000Z",
        183
    ],
    [
        "2023-11-18T04:24:00.000Z",
        -23
    ],
    [
        "2023-11-18T12:17:00.000Z",
        140
    ],
    [
        "2023-11-18T16:18:00.000Z",
        110
    ],
    [
        "2023-11-18T22:11:00.000Z",
        173
    ],
    [
        "2023-11-19T05:23:00.000Z",
        -16
    ],
    [
        "2023-11-19T13:15:00.000Z",
        144
    ],
    [
        "2023-11-19T17:48:00.000Z",
        105
    ],
    [
        "2023-11-19T23:23:00.000Z",
        161
    ],
    [
        "2023-11-20T06:26:00.000Z",
        -9
    ],
    [
        "2023-11-20T14:06:00.000Z",
        151
    ],
    [
        "2023-11-20T19:20:00.000Z",
        90
    ],
    [
        "2023-11-21T00:45:00.000Z",
        149
    ],
    [
        "2023-11-21T07:27:00.000Z",
        1
    ],
    [
        "2023-11-21T14:50:00.000Z",
        162
    ],
    [
        "2023-11-21T20:34:00.000Z",
        68
    ],
    [
        "2023-11-22T02:11:00.000Z",
        142
    ],
    [
        "2023-11-22T08:23:00.000Z",
        12
    ],
    [
        "2023-11-22T15:29:00.000Z",
        173
    ],
    [
        "2023-11-22T21:35:00.000Z",
        42
    ],
    [
        "2023-11-23T03:32:00.000Z",
        139
    ],
    [
        "2023-11-23T09:14:00.000Z",
        26
    ],
    [
        "2023-11-23T16:06:00.000Z",
        185
    ],
    [
        "2023-11-23T22:27:00.000Z",
        17
    ],
    [
        "2023-11-24T04:46:00.000Z",
        141
    ],
    [
        "2023-11-24T10:02:00.000Z",
        41
    ],
    [
        "2023-11-24T16:41:00.000Z",
        195
    ],
    [
        "2023-11-24T23:15:00.000Z",
        -5
    ],
    [
        "2023-11-25T05:52:00.000Z",
        144
    ],
    [
        "2023-11-25T10:47:00.000Z",
        56
    ],
    [
        "2023-11-25T17:16:00.000Z",
        202
    ],
    [
        "2023-11-26T00:00:00.000Z",
        -21
    ],
    [
        "2023-11-26T06:53:00.000Z",
        147
    ],
    [
        "2023-11-26T11:32:00.000Z",
        71
    ],
    [
        "2023-11-26T17:52:00.000Z",
        205
    ],
    [
        "2023-11-27T00:43:00.000Z",
        -31
    ],
    [
        "2023-11-27T07:50:00.000Z",
        149
    ],
    [
        "2023-11-27T12:17:00.000Z",
        84
    ],
    [
        "2023-11-27T18:28:00.000Z",
        204
    ],
    [
        "2023-11-28T01:24:00.000Z",
        -35
    ],
    [
        "2023-11-28T08:44:00.000Z",
        150
    ],
    [
        "2023-11-28T13:02:00.000Z",
        94
    ],
    [
        "2023-11-28T19:05:00.000Z",
        199
    ],
    [
        "2023-11-29T02:06:00.000Z",
        -33
    ],
    [
        "2023-11-29T09:36:00.000Z",
        149
    ],
    [
        "2023-11-29T13:50:00.000Z",
        101
    ],
    [
        "2023-11-29T19:43:00.000Z",
        191
    ],
    [
        "2023-11-30T02:48:00.000Z",
        -27
    ],
    [
        "2023-11-30T10:28:00.000Z",
        148
    ],
    [
        "2023-11-30T14:40:00.000Z",
        106
    ],
    [
        "2023-11-30T20:23:00.000Z",
        182
    ],
    [
        "2023-12-01T03:32:00.000Z",
        -19
    ],
    [
        "2023-12-01T11:19:00.000Z",
        145
    ],
    [
        "2023-12-01T15:35:00.000Z",
        108
    ],
    [
        "2023-12-01T21:06:00.000Z",
        170
    ],
    [
        "2023-12-02T04:18:00.000Z",
        -10
    ],
    [
        "2023-12-02T12:10:00.000Z",
        143
    ],
    [
        "2023-12-02T16:40:00.000Z",
        107
    ],
    [
        "2023-12-02T21:54:00.000Z",
        157
    ],
    [
        "2023-12-03T05:06:00.000Z",
        1
    ],
    [
        "2023-12-03T12:58:00.000Z",
        143
    ],
    [
        "2023-12-03T17:55:00.000Z",
        103
    ],
    [
        "2023-12-03T22:49:00.000Z",
        144
    ],
    [
        "2023-12-04T05:57:00.000Z",
        11
    ],
    [
        "2023-12-04T13:42:00.000Z",
        145
    ],
    [
        "2023-12-04T19:09:00.000Z",
        93
    ],
    [
        "2023-12-04T23:55:00.000Z",
        130
    ],
    [
        "2023-12-05T06:47:00.000Z",
        22
    ],
    [
        "2023-12-05T14:19:00.000Z",
        150
    ],
    [
        "2023-12-05T20:14:00.000Z",
        79
    ],
    [
        "2023-12-06T01:12:00.000Z",
        120
    ],
    [
        "2023-12-06T07:37:00.000Z",
        34
    ],
    [
        "2023-12-06T14:51:00.000Z",
        156
    ],
    [
        "2023-12-06T21:09:00.000Z",
        61
    ],
    [
        "2023-12-07T02:34:00.000Z",
        115
    ],
    [
        "2023-12-07T08:23:00.000Z",
        45
    ],
    [
        "2023-12-07T15:20:00.000Z",
        164
    ],
    [
        "2023-12-07T21:54:00.000Z",
        42
    ],
    [
        "2023-12-08T03:52:00.000Z",
        116
    ],
    [
        "2023-12-08T09:06:00.000Z",
        57
    ],
    [
        "2023-12-08T15:49:00.000Z",
        173
    ],
    [
        "2023-12-08T22:34:00.000Z",
        24
    ],
    [
        "2023-12-09T05:00:00.000Z",
        121
    ],
    [
        "2023-12-09T09:47:00.000Z",
        69
    ],
    [
        "2023-12-09T16:19:00.000Z",
        181
    ],
    [
        "2023-12-09T23:10:00.000Z",
        6
    ],
    [
        "2023-12-10T06:00:00.000Z",
        127
    ],
    [
        "2023-12-10T10:28:00.000Z",
        80
    ],
    [
        "2023-12-10T16:50:00.000Z",
        189
    ],
    [
        "2023-12-10T23:46:00.000Z",
        -9
    ],
    [
        "2023-12-11T06:53:00.000Z",
        134
    ],
    [
        "2023-12-11T11:08:00.000Z",
        89
    ],
    [
        "2023-12-11T17:24:00.000Z",
        195
    ],
    [
        "2023-12-12T00:23:00.000Z",
        -23
    ],
    [
        "2023-12-12T07:43:00.000Z",
        140
    ],
    [
        "2023-12-12T11:49:00.000Z",
        96
    ],
    [
        "2023-12-12T18:01:00.000Z",
        200
    ],
    [
        "2023-12-13T01:03:00.000Z",
        -33
    ],
    [
        "2023-12-13T08:30:00.000Z",
        144
    ],
    [
        "2023-12-13T12:32:00.000Z",
        101
    ],
    [
        "2023-12-13T18:42:00.000Z",
        203
    ],
    [
        "2023-12-14T01:45:00.000Z",
        -40
    ],
    [
        "2023-12-14T09:17:00.000Z",
        146
    ],
    [
        "2023-12-14T13:18:00.000Z",
        103
    ],
    [
        "2023-12-14T19:26:00.000Z",
        202
    ],
    [
        "2023-12-15T02:30:00.000Z",
        -42
    ],
    [
        "2023-12-15T10:04:00.000Z",
        147
    ],
    [
        "2023-12-15T14:09:00.000Z",
        102
    ],
    [
        "2023-12-15T20:14:00.000Z",
        198
    ],
    [
        "2023-12-16T03:17:00.000Z",
        -39
    ],
    [
        "2023-12-16T10:52:00.000Z",
        148
    ],
    [
        "2023-12-16T15:08:00.000Z",
        100
    ],
    [
        "2023-12-16T21:07:00.000Z",
        188
    ],
    [
        "2023-12-17T04:07:00.000Z",
        -31
    ],
    [
        "2023-12-17T11:39:00.000Z",
        151
    ],
    [
        "2023-12-17T16:17:00.000Z",
        95
    ],
    [
        "2023-12-17T22:06:00.000Z",
        173
    ],
    [
        "2023-12-18T04:58:00.000Z",
        -19
    ],
    [
        "2023-12-18T12:27:00.000Z",
        156
    ],
    [
        "2023-12-18T17:38:00.000Z",
        86
    ],
    [
        "2023-12-18T23:15:00.000Z",
        155
    ],
    [
        "2023-12-19T05:52:00.000Z",
        -2
    ],
    [
        "2023-12-19T13:13:00.000Z",
        165
    ],
    [
        "2023-12-19T19:03:00.000Z",
        69
    ],
    [
        "2023-12-20T00:37:00.000Z",
        137
    ],
    [
        "2023-12-20T06:46:00.000Z",
        16
    ],
    [
        "2023-12-20T13:58:00.000Z",
        175
    ],
    [
        "2023-12-20T20:19:00.000Z",
        47
    ],
    [
        "2023-12-21T02:09:00.000Z",
        126
    ],
    [
        "2023-12-21T07:42:00.000Z",
        36
    ],
    [
        "2023-12-21T14:42:00.000Z",
        185
    ],
    [
        "2023-12-21T21:23:00.000Z",
        23
    ],
    [
        "2023-12-22T03:40:00.000Z",
        124
    ],
    [
        "2023-12-22T08:37:00.000Z",
        55
    ],
    [
        "2023-12-22T15:24:00.000Z",
        194
    ],
    [
        "2023-12-22T22:19:00.000Z",
        2
    ],
    [
        "2023-12-23T05:00:00.000Z",
        129
    ],
    [
        "2023-12-23T09:32:00.000Z",
        71
    ],
    [
        "2023-12-23T16:05:00.000Z",
        201
    ],
    [
        "2023-12-23T23:07:00.000Z",
        -15
    ],
    [
        "2023-12-24T06:07:00.000Z",
        137
    ],
    [
        "2023-12-24T10:25:00.000Z",
        84
    ],
    [
        "2023-12-24T16:46:00.000Z",
        204
    ],
    [
        "2023-12-24T23:52:00.000Z",
        -26
    ],
    [
        "2023-12-25T07:04:00.000Z",
        144
    ],
    [
        "2023-12-25T11:17:00.000Z",
        93
    ],
    [
        "2023-12-25T17:26:00.000Z",
        204
    ],
    [
        "2023-12-26T00:34:00.000Z",
        -31
    ],
    [
        "2023-12-26T07:54:00.000Z",
        149
    ],
    [
        "2023-12-26T12:06:00.000Z",
        98
    ],
    [
        "2023-12-26T18:07:00.000Z",
        201
    ],
    [
        "2023-12-27T01:13:00.000Z",
        -32
    ],
    [
        "2023-12-27T08:39:00.000Z",
        151
    ],
    [
        "2023-12-27T12:53:00.000Z",
        101
    ],
    [
        "2023-12-27T18:46:00.000Z",
        196
    ],
    [
        "2023-12-28T01:52:00.000Z",
        -30
    ],
    [
        "2023-12-28T09:21:00.000Z",
        151
    ],
    [
        "2023-12-28T13:38:00.000Z",
        101
    ],
    [
        "2023-12-28T19:26:00.000Z",
        189
    ],
    [
        "2023-12-29T02:30:00.000Z",
        -25
    ],
    [
        "2023-12-29T10:01:00.000Z",
        149
    ],
    [
        "2023-12-29T14:23:00.000Z",
        100
    ],
    [
        "2023-12-29T20:05:00.000Z",
        181
    ],
    [
        "2023-12-30T03:07:00.000Z",
        -19
    ],
    [
        "2023-12-30T10:38:00.000Z",
        147
    ],
    [
        "2023-12-30T15:09:00.000Z",
        98
    ],
    [
        "2023-12-30T20:44:00.000Z",
        170
    ],
    [
        "2023-12-31T03:43:00.000Z",
        -10
    ],
    [
        "2023-12-31T11:13:00.000Z",
        145
    ],
    [
        "2023-12-31T16:00:00.000Z",
        96
    ],
    [
        "2023-12-31T21:26:00.000Z",
        158
    ],
    [
        "2024-01-01T04:17:00.000Z",
        3
    ]
];