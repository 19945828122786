import { FC, useEffect } from "react";
import "./tidelayer.css";
import { instantiateTideD3 } from "./app";
import { Link } from "react-router-dom";

// create a svg of a wave 96x48, filled along a center line transparently white
const sinwavesvg = (
	<svg width="96" height="48">
		<path
			d="M 0 24 Q 32 0 64 24 T 128 24 V 48 H 0 Z"
			fill="white"
			fillOpacity="0.5"
		/>
	</svg>
);

const TideLayer: FC = () => {
	useEffect(() => {
		instantiateTideD3();
	}, []);

	return (
		<div id="bg">
			<div id="tidelayer"></div>
			<Link className="link" to="/" title="back">
				{sinwavesvg}
			</Link>
		</div>
	);
};

export default TideLayer;
