import { FC } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Skytime from "./skytime/skytime";
import Home from "./Home";
import TideLayer from "./tidelayer/tidelayer";

const App: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/skytime/" element={<Skytime />} />
      <Route path="/tidelayer/" element={<TideLayer />} />
      <Route path="/" element={<Home />} />
    </Routes>
  </BrowserRouter>
);

export default App;
