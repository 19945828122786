import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useInterval } from "usehooks-ts";
import "./skytime.css";

const Skytime: FC = () => {
	const frames = 8272;
	const fps = 16;
	const totalSeconds = frames / fps;
	const getCycleTime = () => {
		const milliTime = Date.now();
		const secondTime = milliTime / 1000;
		return secondTime % totalSeconds;
	};
	const [cycleTime, setCycleTime] = useState(getCycleTime());
	useInterval(() => {
		setCycleTime(getCycleTime());
	}, 1000);
	const cycleFrame = cycleTime * fps;
	const cycleSec = Math.floor(cycleTime % totalSeconds);

	const color = "rgba(255,255,255,0.5)";
	const logo = (
		<svg>
			<circle cx="200" cy="200" r="80" fill={color} />
			<g
				transform-origin="center"
				transform-box="fill-box"
				transform={`rotate(${-(cycleFrame / frames) * 360})`}
			>
				<path
					d=" M 300 200 A 100 100 0 1 1 200 100"
					fill="none"
					stroke={color}
					strokeWidth="30"
				/>
			</g>
		</svg>
	);

	const url = "https://skytime.s3.us-west-2.amazonaws.com/st.mp4";

	return (
		<div className="container">
			<video width="1920" height="1080" autoPlay loop muted>
				<source src={`${url}#t=${cycleSec}`} type="video/mp4" />
			</video>
			<Link to="/" title="back">
				{logo}
			</Link>
		</div>
	);
};
export default Skytime;
