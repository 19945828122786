import { FC } from "react";
import { useCounter, useInterval } from "usehooks-ts";
import "./App.css";

const BASE = 0.05;
const backgroundImage = `url(${process.env.PUBLIC_URL + "/bg1.jpg"})`;

const getRandomOpacity = () => {
	const opacity = Math.random() * (1.0 - BASE) + BASE;
	const letterSpacing = 40;
	const transition = "opacity 1s ease-in-out";
	return { letterSpacing, opacity, transition };
};

const Home: FC = () => {
	const { increment } = useCounter();
	useInterval(() => {
		increment();
	}, 2000);

	return (
		<div className="App" style={{ backgroundImage }}>
			<div>
				<span style={getRandomOpacity()}>BAR</span>
				<span style={getRandomOpacity()}>BE</span>
				<span style={getRandomOpacity()}>G</span>
				<span>AL</span>
			</div>
      <div>
			<div>
				<a className="link" href="/skytime">
					skytime
				</a>
			</div>
			<div>
				<a className="link" href="/tidelayer">
					tidelayer
				</a>
			</div>
      </div>
		</div>
	);
};

export default Home;
